import { useEffect, useMemo } from 'react';
import { useI18n } from 'next-localization';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { addCartItem, deleteCartItem } from 'components/cart/cart-slice';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { OptionalAddonDetails } from './OptionalAddOnsCarousel';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { pushViewItem, pushAddToCart, pushRemoveFromCart } from 'lib/google-analytics/commerce';

type OptionalAddOnsCardProps = {
  product: OptionalAddonDetails;
};

function OptionalAddOnsCard(props: OptionalAddOnsCardProps): JSX.Element {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { cartItemsData } = useAppSelector((state) => state.cart);
  const isDiscounted = props.product !== null && props.product.unitPrice < props.product.listPrice;
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const cart = useAppSelector((state) => state.cart);
  const cartItem = cart?.cartItemsData?.cartSummary;

  const productInCart = useMemo(() => {
    return cartItemsData?.cartItems?.find((c) => c.cartItem.productId === props.product?.productId);
  }, [cartItemsData?.cartItems, props.product?.productId]);

  useEffect(() => {
    pushViewItem(
      props.product?.currencyIsoCode ?? '',
      props.product?.listPrice ? props.product?.listPrice?.toString() : 'NA',
      {
        id: props.product?.productId ?? '',
        sku: props.product?.productId ?? '',
        name: props.product?.productName ?? '',
        price: props.product?.listPrice ? props.product?.listPrice?.toString() : 'NA',
        discount: 'NA',
        brand: 'NA',
        variant: props.product?.productSku ?? '',
        itemCategory: props.product?.primaryCategory,
        itemCategory2: props.product?.additionalCategory1,
        itemCategory3: props.product?.additionalCategory2,
        itemCategory4: props.product?.additionalCategory3,
        productSku: props.product?.productSku,
      }
    );
  }, [
    props.product?.additionalCategory1,
    props.product?.additionalCategory2,
    props.product?.additionalCategory3,
    props.product?.currencyIsoCode,
    props.product?.listPrice,
    props.product?.primaryCategory,
    props.product?.productId,
    props.product?.productName,
    props.product?.productSku,
  ]);

  const onClickInCartHandler = (): void => {
    if (productInCart) {
      dispatch(
        deleteCartItem({ cartItemId: productInCart.cartItem.cartItemId, bearerToken: bearerToken })
      );
      pushRemoveFromCart(
        props.product?.currencyIsoCode ?? '',
        cartItem?.totalListPrice?.toString() ?? '',
        {
          id: props.product?.productId ?? '',
          sku: props.product?.productId ?? '',
          name: props.product?.productName ?? '',
          price: props.product?.listPrice ? props.product?.listPrice?.toString() : 'NA',
          discount: 'NA',
          brand: 'NA',
          variant: props.product?.productSku ?? '',
          itemCategory: props.product?.primaryCategory,
          itemCategory2: props.product?.additionalCategory1,
          itemCategory3: props.product?.additionalCategory2,
          itemCategory4: props.product?.additionalCategory3,
          productSku: props.product?.productSku,
        }
      );
    }
  };

  const onClickAddToCartHandler = (): void => {
    dispatch(
      addCartItem({ sku: props.product?.productId ?? '', quantity: 1, bearerToken: bearerToken })
    );

    pushAddToCart(
      props.product?.currencyIsoCode ?? '',
      props.product?.listPrice ? props.product?.listPrice?.toString() : 'NA',
      {
        id: props.product?.productId ?? '',
        sku: props.product?.productId ?? '',
        name: props.product?.productName ?? '',
        price: props.product?.listPrice ? props.product?.listPrice?.toString() : 'NA',
        discount: 'NA',
        brand: 'NA',
        variant: props.product?.productSku ?? '',
        itemCategory: props.product?.primaryCategory,
        itemCategory2: props.product?.additionalCategory1,
        itemCategory3: props.product?.additionalCategory2,
        itemCategory4: props.product?.additionalCategory3,
        productSku: props.product?.productSku,
      }
    );
  };

  return (
    <div
      className={`border rounded border-base-normal flex flex-col items-center p-6 text-almost-black bg-white relative`}
    >
      <div className="h-2/3 min-h-[126px]">
        {props.product?.imgDetails?.jsonValue.value?.src !== undefined &&
          props.product?.imgDetails?.jsonValue.value?.src?.length > 0 && (
            <NextImage
              field={props.product?.imgDetails?.jsonValue}
              className=""
              width={1200}
              height={762}
              alt={`${props.product?.productName} image`}
            />
          )}
      </div>
      <h3 className="font-bold text-lg text-center mb-2 w-full leading-5.4 font-avidwalsheimbold">
        {props.product?.productName}
      </h3>
      <div className="text-xl text-center mb-4 min-h-[44px]">
        <div className={`${isDiscounted ? 'block' : 'hidden'} text-neutral text-xs line-through`}>
          <span>
            {t(`Currency_Symbol_${props.product?.currencyIsoCode}`)}
            {props.product?.listPrice}
          </span>
        </div>
        <div className="">
          <span>
            {t(`Currency_Symbol_${props.product?.currencyIsoCode}`)}
            {props.product?.unitPrice}
          </span>
        </div>
      </div>
      {productInCart ? (
        <button
          onClick={(): void => onClickInCartHandler()}
          className="text-center font-bold text-base btn-primary py-[10px] px-3"
        >
          {t('Cart_In_Cart')}
        </button>
      ) : (
        <button
          onClick={(): void => {
            onClickAddToCartHandler();
          }}
          className="text-center font-bold text-base btn-primary py-[10px] px-3"
        >
          {t('Cart_Add')}
        </button>
      )}
      {props.product?.productUrl && props.product?.productUrl.length > 0 && (
        <a
          href={props.product?.productUrl ?? ''}
          className="text-center text-base underline text-primary font-bold mt-2"
        >
          {t('Cart_Details')}
        </a>
      )}
    </div>
  );
}

export default OptionalAddOnsCard;
