import {
  Field,
  PlaceholderComponentProps,
  Text,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ClickthroughBlocksContainerProps = ComponentProps &
  PlaceholderComponentProps & {
    fields: {
      title: Field<string>;
    };
    params: {
      layout: string;
      marginTop: string;
      marginBottom: string;
    };
    clickThroughBlockContainerItems: JSX.Element;
  };

const GetClassFromMargin = (MarginType: string, Margin: string): string => {
  if (Margin == null) {
    return '';
  }

  switch (Margin.toLowerCase()) {
    case '0px':
      return `${MarginType}0`;
    case '4px':
      return `${MarginType}1`;
    case '8px':
      return `${MarginType}2`;
    case '16px':
      return `${MarginType}4`;
    case '24px':
      return `${MarginType}6`;
    case '32px':
      return `${MarginType}8`;
    case '40px':
      return `${MarginType}10`;
    case '48px':
      return `${MarginType}12`;
    case '64px':
      return `${MarginType}16`;
    case '80px':
      return `${MarginType}20`;
    default:
      return `${MarginType == 'mb-' ? 'mb-20' : ''}`;
  }
};

const ClickthroughBlocksContainer = (props: ClickthroughBlocksContainerProps): JSX.Element => {
  const isTwoColumn = props?.params?.layout == 'two-column';
  const topmargin = props?.params?.marginTop
    ? `${GetClassFromMargin('mt-', props.params.marginTop)}`
    : '';
  const bottommargin = props?.params?.marginBottom
    ? `${GetClassFromMargin('mb-', props.params.marginBottom)}`
    : 'mb-20';
  const clickThroughBlockItemClasses =
    '[&_.clickthrough-block-item]:sm:my-0 [&_.clickthrough-block-item]:lg:my-0 gap-x-8 [&_.clickthrough-block-item]:space-x-8';
  return (
    <div
      className={`px-2  sm:mb-10  lg:px-4 ${topmargin ? 'lg:' + topmargin : ''} ${
        bottommargin ? 'lg:' + bottommargin : ''
      }`}
    >
      {props.fields?.title != null && props.fields?.title?.value != '' && (
        <div className={`text-center mt-10 lg:mt-20 lg:mb-4 h2`}>
          <Text tag="h2" field={props.fields.title} />
        </div>
      )}
      <div
        className={`mx-auto flex flex-col lg:flex-row  placeholder:lg:space-x-8 max-w-content box-content lg:flex-wrap ${clickThroughBlockItemClasses} ${
          isTwoColumn
            ? '[&_.clickthrough-block-item]:basis-1/2-gap-8'
            : '[&_.clickthrough-block-item]:basis-1/3-gap-8'
        }`}
      >
        {props.clickThroughBlockContainerItems}
      </div>
    </div>
  );
};

export default withPlaceholder({
  placeholder: 'jss-clickthrough-blocks-container',
  prop: 'clickThroughBlockContainerItems',
})(ClickthroughBlocksContainer);
