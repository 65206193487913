import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { Placeholder, LayoutServiceData, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import MetaData from 'components/foundation/non-sitecore/meta-data/MetaData';
import { useI18n } from 'next-localization';
import { useAppDispatch } from 'lib/store/hooks';
import { navigationActions } from 'lib/navigation/navigation-slice';
import { isEmpty } from 'lib/utils/string-format';
import TagManager from 'react-gtm-module';
import {
  gtWalshiemAvidRegular,
  gtWalshiemAvidLight,
  gtWalshiemAvidMedium,
  gtWalshiemAvidBold,
} from 'lib/fonts/font-loader';
import { pushCoveoSearchEvent } from 'lib/google-analytics/coveo-site-search';
import CoveoSearchEngineContext from 'lib/coveo/coveo-context';
import { getLanguageName } from 'lib/navigation/navigation-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface DefaultLayoutProps {
  layoutData: LayoutServiceData;
}

const DefaultLayout = ({ layoutData }: DefaultLayoutProps): JSX.Element => {
  const editorActive = useSitecoreContext()?.sitecoreContext?.pageEditing;

  const { route } = layoutData.sitecore;
  const { locale, t } = useI18n();
  const lang = locale();
  const allContentLabel = t('All Content');
  const dispatch = useAppDispatch();
  dispatch(navigationActions.changeLanguage(lang));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchEngine, setSearchEngine] = useState<any>(null);

  const gaTrackingId = process.env.NEXT_PUBLIC_GA_TRACKING_ID || '';

  useEffect(() => {
    if (!isEmpty(gaTrackingId) && !editorActive) {
      TagManager.initialize({ gtmId: gaTrackingId });
    }
  }, [editorActive, gaTrackingId]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME !== undefined &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL !== undefined &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT !== undefined &&
      process.env.NEXT_PUBLIC_CLIENT_APM_DISABLED !== 'true'
    ) {
      const apmInit = async (): Promise<void> => {
        const apm = await import('@elastic/apm-rum');
        apm.init({
          serviceName: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME,
          serverUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
          environment: process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT,
          transactionSampleRate: process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
            ? +process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
            : 1,
        });
      };

      apmInit();
    }
  });

  const organizationId =
    process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
      ? process.env.COVEO_ORGANIZATION
      : '';

  useEffect(() => {
    const ignoreLangTabs: string[] = ['Videos'];
    const initializeSearchEngine = async (): Promise<void> => {
      const coveo = await import('@coveo/atomic-react');
      const engine = coveo.buildSearchEngine({
        configuration: {
          accessToken:
            process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
              ? process.env.COVEO_ACCESS_TOKEN
              : '',
          organizationId,
          organizationEndpoints: coveo.getOrganizationEndpoints(organizationId),
          search: {
            pipeline:
              process.env.COVEO_PIPELINE && process.env.COVEO_PIPELINE.length > 0
                ? process.env.COVEO_PIPELINE
                : '',
            searchHub: 'AvidComGlobalSearch',
          },
          analytics: {
            analyticsClientMiddleware: (...[, payload]) => {
              pushCoveoSearchEvent(payload);
              return payload;
            },
          },
          preprocessRequest: (request, clientOrigin) => {
            const language = locale();
            if (language == null || language.length === 0) {
              return request;
            }

            if (clientOrigin == 'searchApiFetch') {
              const body = JSON.parse(request.body?.toString() ?? '');
              if (!ignoreLangTabs.includes(body['tab'])) {
                body.aq = `@language==(${getLanguageName(language)}) OR (NOT @language)`;
              }
              request.body = JSON.stringify(body);
            }

            return request;
          },
        },
      });

      setSearchEngine(engine);
    };

    initializeSearchEngine();
  }, [allContentLabel, locale, organizationId]);

  const layoutStructure = (
    <div
      className={`flex flex-col justify-between relative ${gtWalshiemAvidRegular.variable} ${gtWalshiemAvidLight.variable} ${gtWalshiemAvidMedium.variable} ${gtWalshiemAvidBold.variable}`}
    >
      <header className="sticky top-0 z-50">
        {route && <Placeholder name="jss-header" rendering={route} />}
        <div className="absolute w-full" id="sticky-navigation"></div>
        <div id="local-navigation-mobile"></div>
      </header>
      <main className="mb-auto">{route && <Placeholder name="jss-main" rendering={route} />}</main>
      <footer className="bg-almost-black text-white p-8">
        {route && <Placeholder name="jss-footer" rendering={route} />}
      </footer>
    </div>
  );
  return (
    <>
      <Head>
        <title>{((route?.fields?.metaPageTitle as Field)?.value as string) || 'Page'}</title>
        <MetaData sitecore={layoutData.sitecore} />
        <meta charSet="UTF-8" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {editorActive || !searchEngine ? (
        layoutStructure
      ) : (
        <CoveoSearchEngineContext.Provider value={searchEngine}>
          {layoutStructure}
        </CoveoSearchEngineContext.Provider>
      )}
      <div>{route && <Placeholder name="jss-extras" rendering={route} />}</div>
    </>
  );
};

export default DefaultLayout;
