/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, Text, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { useState, useEffect } from 'react';
import Modal from 'components/foundation/non-sitecore/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { useI18n } from 'next-localization';
import { getAllPartners } from 'lib/learn/get-all-partners';
import { PartnerProps, Partners } from 'lib/learn/find-partner-model';

export type FindPartnerProps = {
  fields: {
    heading: Field<string>;
  };
};

function FindPartner(props: FindPartnerProps): JSX.Element {
  const { t } = useI18n();
  const [partnerData, setpartnerData] = useState<PartnerProps[]>([]);
  const [filteredData, setFilteredData] = useState<PartnerProps[]>([]);
  const [partnerTypes, setPartnerTypes] = useState<string[]>([]);
  const [countries, setcountries] = useState<string[]>([]);
  const [courses, setCourses] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[]>([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalData, setModalData] = useState<PartnerProps>();
  const [hydrate, setHydrate] = useState(false);
  const editorActive = isEditorActive();

  function sortArrayAscending(arr: string[]): string[] {
    const sortedArray = arr.slice().sort((a, b) => a?.localeCompare(b));
    return sortedArray;
  }

  const onClickOpenPartnerModalHandler = (partnerId: string): void => {
    if (!displayModal && typeof window != 'undefined' && window.document) {
      const selectedPartner = partnerData?.find((_partner) => {
        return _partner.partnerId === partnerId;
      });

      setModalData(selectedPartner);
      setDisplayModal(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const onClickClosePartnerModalHandler = (): void => {
    setDisplayModal(false);
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    if (!editorActive) {
      getAllPartners().then((data: Partners) => {
        const _partnerTypes: string[] = [];
        const _countries: string[] = [];
        const _courses: string[] = [];

        data.data?.map((_partner) => {
          _partner.partnerTypesString = _partner.partnerTypes?.join(', ') as string;
          _partner.coursesString = _partner.courses?.map((c) => c?.courseName).join(', ') as string;
          if (_partner.questionMarkGroupId) {
            _partner.associateMeUrl = `${process.env.NEXT_PUBLIC_MY_AVID_ASSOCIATEME_ENDPOINT?.replace(
              '{0}',
              _partner.questionMarkGroupId
            )}`;
          } else {
            _partner.associateMeUrl = '';
          }

          _partner.partnerTypes?.map((_partnerType) => {
            if (_partnerTypes.indexOf(_partnerType) === -1) {
              _partnerTypes.push(_partnerType);
            }
          });
          if (_countries.indexOf(_partner.country) === -1) {
            _countries.push(_partner.country);
          }
          _partner.courses?.map((_course) => {
            if (_courses.indexOf(_course?.courseName as string) === -1) {
              _courses.push(_course?.courseName as string);
            }
          });
        });
        setPartnerTypes(sortArrayAscending(_partnerTypes));
        setcountries(sortArrayAscending(_countries));
        setCourses(sortArrayAscending(_courses));
        setpartnerData(data.data as PartnerProps[]);
        setHydrate(true);
      });
    }
  }, [editorActive]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const _regions: string[] = [];
    partnerData?.map((_partner) => {
      if (_partner.country == event.target.value) {
        if (_regions.indexOf(_partner.state as string) === -1) {
          _regions.push(_partner.state as string);
        }
      }
      setRegions(sortArrayAscending(_regions));
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const filterParams: any = {};
    const formData = new FormData(event.currentTarget);
    for (const [key, value] of Array.from(formData.entries())) {
      if (value?.toString().trim() != '') {
        filterParams[key] = value;
      }
    }
    const filteredData = partnerData.filter((_partnerData) => {
      return Object.keys(filterParams).every((key) => {
        const filterValue = filterParams[key as keyof PartnerProps];
        const partnerDataValue = _partnerData[key as keyof PartnerProps];

        if (filterValue === undefined) {
          return true;
        }

        if (typeof filterValue === 'string' && typeof partnerDataValue === 'string') {
          return partnerDataValue.toLowerCase().includes(filterValue.toLowerCase());
        }
        return false;
      });
    });
    setFilteredData(filteredData);
  };

  return (
    <>
      {(hydrate || editorActive) && (
        <>
          <div className="flex px-6 bg-slate-300 2xl:justify-end sm:my-5 lg:my-10">
            <div className="flex flex-col gap-8 w-full">
              <Text
                tag="h2"
                fields={props.fields.heading}
                className="tracking-[8.96px] uppercase"
              />
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  <div>
                    <label className="block mb-2 leading-24 font-bold text-almost-black">
                      {t('LEARN_PARTNER_TYPE')}
                    </label>
                    <select
                      name="partnerTypesString"
                      className="block w-full border border-base-darker py-1.5 px-2.5 text-grayblack appearance-none bg-arrow"
                    >
                      <option value="">{t('LEARN_PARTNER_TYPE_DD_PH')}</option>
                      {partnerTypes.map((partnerType) => (
                        <option key={partnerType} value={partnerType}>
                          {partnerType}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 leading-24 font-bold text-almost-black">
                      {t('LEARN_COUNTRY')}
                    </label>
                    <select
                      name="country"
                      className="block w-full border border-base-darker py-1.5 px-2.5 text-grayblack appearance-none bg-arrow"
                      onChange={handleCountryChange}
                    >
                      <option value="">{t('LEARN_COUNTRY_DD_PH')}</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 leading-24 font-bold text-almost-black">
                      {t('LEARN_REGION')}
                    </label>
                    <select
                      name="state"
                      className="block w-full border border-base-darker py-1.5 px-2.5 text-grayblack appearance-none bg-arrow"
                    >
                      <option value="">{t('LEARN_Region_DD_PH')}</option>
                      {regions.map((region) => (
                        <option key={region} value={region}>
                          {region}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                  <div>
                    <label className="block mb-2 leading-24 font-bold text-almost-black">
                      {t('LEARN_COURSES_OFFERED')}
                    </label>
                    <select
                      name="coursesString"
                      className="block w-full border border-base-darker py-1.5 px-2.5 text-grayblack appearance-none bg-arrow"
                    >
                      <option value="">{t('LEARN_COURSE_DD_PH')}</option>
                      {courses.map((course) => (
                        <option key={course} value={course}>
                          {course}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 leading-24 font-bold text-almost-black">
                      {t('LEARN_PARTNER_NAME')}
                    </label>
                    <input
                      type="text"
                      name="partnerName"
                      placeholder={t('LEARN_PARTNER_NAME_TXT_PH')}
                      className="block w-full border border-base-darker py-1.5 px-2.5 text-grayblack"
                    />
                  </div>
                  <div>
                    <button type="submit" className="btn-primary">
                      {t('LEARN_SUBMIT')}
                    </button>
                  </div>
                </div>
                <div
                  className={`overflow-x-auto ${
                    filteredData && filteredData.length ? '' : 'hidden'
                  }`}
                >
                  <table className="w-full table-auto text-left ">
                    <thead>
                      <tr className="border-y border-neutral-lighter">
                        <th className="p-3 text-almost-black">{t('LEARN_PARTNER_NAME_TBL')}</th>
                        <th className="p-3 text-almost-black">{t('LEARN_PARTNER_LEVEL_TBL')}</th>
                        <th className="p-3 text-almost-black">{t('LEARN_PARTNER_ADDRESS_TBL')}</th>
                        <th className="p-3 text-almost-black">{t('LEARN_PARTNER_PHONE_TBL')}</th>
                        <th className="p-3 text-almost-black">
                          {t('LEARN_PARTNER_ASSOCIATE_TBL')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((_pData, _idx) => (
                        <tr
                          className="odd:bg-base-lighter border-b border-neutral-lighter"
                          key={_idx}
                        >
                          <td className="p-3">
                            <button
                              className="btn-link btn-small !body-link underline after:content-none"
                              onClick={(): void => {
                                onClickOpenPartnerModalHandler(_pData.partnerId);
                              }}
                            >
                              {_pData.partnerName}
                            </button>
                          </td>
                          <td className="p-3 text-almost-black">{_pData.partnerTypes}</td>
                          <td className="p-3 text-almost-black">
                            {[_pData.address1, _pData.address2].filter(Boolean).join(', ')}
                          </td>
                          <td className="p-3 text-almost-black">{_pData.phoneNumber}</td>
                          <td className="p-3">
                            {_pData.associateMeUrl !== '' && (
                              <Link
                                className="btn-link-underline"
                                href={_pData?.associateMeUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span>{t('LEARN_PARTNER_ASSOCIATE_ME_TBL')}</span>
                                <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
          {displayModal && (
            <Modal
              onClose={onClickClosePartnerModalHandler}
              show={displayModal}
              useModalBackdrop={false}
              titleClassName="text-almost-black"
              rootId="modal-root"
            >
              <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen z-50 w-96">
                <div
                  onClick={onClickClosePartnerModalHandler}
                  className="modal-backdrop absolute top-0 bg-avid-backdrop h-screen opacity-50 w-screen z-10"
                />
                {modalData && (
                  <div className="relative bg-white flex flex-col items-center z-50 p-10 max-w-[350px] xl:max-w-[850px] rounded">
                    <button
                      type="button"
                      className="absolute right-6 top-5 text-[26px]"
                      onClick={onClickClosePartnerModalHandler}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <div className="flex flex-col">
                      <div>
                        <h2 className="mb-2">{modalData.partnerName}</h2>
                        <a
                          className="btn-link btn-small after:content-none"
                          href={`${
                            String(modalData.partnerURL).startsWith('http://') ||
                            String(modalData.partnerURL).startsWith('https://')
                              ? ''
                              : 'https://'
                          }${modalData.partnerURL}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {modalData.partnerURL}
                        </a>
                      </div>
                      <div className="grid grid-cols-2 gap-6 mt-5">
                        <div className="mb-6">
                          <label className="block mb-2 font-bold">Address</label>
                          <span>
                            {[
                              modalData.address1,
                              modalData.address2,
                              modalData.state,
                              modalData.country,
                            ]
                              .filter(Boolean)
                              .join(', ')}
                          </span>
                        </div>
                        <div className="mb-6">
                          <label className="block mb-2 font-bold">Courses Offered</label>
                          <span>{modalData.coursesString}</span>
                        </div>
                        <div className="mb-6">
                          <label className="block mb-2 font-bold">Telephone</label>
                          <span>{modalData.phoneNumber}</span>
                        </div>
                        <div className="mb-6">
                          <label className="block mb-2 font-bold">Email</label>
                          <span>{modalData.email}</span>
                        </div>
                      </div>
                      <div className="text-right">
                        {modalData.associateMeUrl !== '' && (
                          <Link
                            className="btn-link-underline"
                            href={modalData?.associateMeUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('LEARN_PARTNER_ASSOCIATE_ME_TBL')}
                            <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
}

export default FindPartner;
