import { GetStaticComponentProps, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { TableProps } from '../components/table/jsxtablemodels';
import JSXRow from '../components/table/JSXRow';
import { getTableData } from '../components/table/table-utils';

const Table = (props: TableProps): JSX.Element => {
  const rows = props?.fields?.data;
  const columnItems = rows[0]?.columnAttributes?.results?.length;
  const columnCount = columnItems ? columnItems : 4;
  const editorActive = isEditorActive();

  if (editorActive && (rows == null || !rows.length)) {
    return (
      <p className="editor-message">
        Please configure your datasource to include rows and columns.
      </p>
    );
  }
  if (rows != null && rows.length) {
    return (
      <div className=" my-16 px-5">
        <div className="max-w-content mx-auto overflow-auto" role="region" tabIndex={0}>
          <table className={`w-full ${props.params.Width === 'Fit Content' && 'lg:w-fit'}`}>
            <tbody>
              {rows.map((row, index) => (
                <JSXRow {...row} columnCount={columnCount} {...props.params} key={index} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return <></>;
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const language = layoutData.sitecore.context.language;
  const rowsResponse = await getTableData(rendering.dataSource, language);
  return {
    fields: {
      data: rowsResponse,
    },
    params: rendering.params,
  };
};

export default Table;
