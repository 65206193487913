import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import {
  Placeholder,
  getPublicUrl,
  LayoutServiceData,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import MetaData from 'components/foundation/non-sitecore/meta-data/MetaData';
import { useI18n } from 'next-localization';
import { useAppDispatch } from 'lib/store/hooks';
import { navigationActions } from 'lib/navigation/navigation-slice';
import { isEmpty } from 'lib/utils/string-format';
import TagManager from 'react-gtm-module';
import {
  gtWalshiemAvidRegular,
  gtWalshiemAvidLight,
  gtWalshiemAvidMedium,
  gtWalshiemAvidBold,
} from 'lib/fonts/font-loader';
import { HostingApplicationListener } from 'lib/marketplace/hosting-application-listener';

// Prefix public assets with a public URL to enable compatibility with Sitecore Experience Editor.
// If you're not supporting the Experience Editor, you can remove this.
const publicUrl = getPublicUrl();

interface MarketplaceLayoutProps {
  layoutData: LayoutServiceData;
}

const MarketplaceLayout = ({ layoutData }: MarketplaceLayoutProps): JSX.Element => {
  const { route } = layoutData.sitecore;
  const { locale } = useI18n();
  const lang = locale();
  const dispatch = useAppDispatch();
  dispatch(navigationActions.changeLanguage(lang));

  const gaTrackingId = process.env.NEXT_PUBLIC_GA_TRACKING_ID_MARKETPLACE || '';
  useEffect(() => {
    if (!isEmpty(gaTrackingId)) {
      TagManager.initialize({ gtmId: gaTrackingId });
    }
  }, [gaTrackingId]);

  useEffect(() => {
    document.body.classList.add('marketplace-page');
    if (![].at) {
      // Avid Link uses an older version of Chromium under the hood.
      // It doesn't support .at method. So, here we add a polyfill for that.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Array.prototype.at = function (index): any {
        if (index >= 0) {
          return this[index];
        } else {
          return this[this.length + index];
        }
      };
    }
    return (): void => {
      document.body.classList.remove('.marketplace-page');
    };
  }, []);

  useEffect(() => {
    if (!navigator) {
      return;
    }
    if (/Avid Link Desktop App/gi.test(navigator.userAgent)) {
      document.body.classList.add('avid-link-desktop');
      // we need this HostingApplicationListener in order to avoid page reloads inside Avid Link
      // each time when the app gets new token
      window['hostingApplicationListener'] = new HostingApplicationListener();
    }
    if (/gonative/gi.test(navigator.userAgent)) {
      document.body.classList.add('avid-link-mobile');
    }
    if (/iPad|iPhone/gi.test(navigator.userAgent)) {
      document.body.classList.add('avid-link-ios');
    } else if (/Android/gi.test(navigator.userAgent)) {
      document.body.classList.add('avid-link-android');
    }

    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME !== undefined &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL !== undefined &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT !== undefined &&
      process.env.NEXT_PUBLIC_CLIENT_APM_DISABLED !== 'true'
    ) {
      const apmInit = async (): Promise<void> => {
        const apm = await import('@elastic/apm-rum');
        apm.init({
          serviceName: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME,
          serverUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
          environment: process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT,
          transactionSampleRate: process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
            ? +process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
            : 1,
        });
      };

      apmInit();
    }

    return (): void => {
      document.body.classList.remove(
        '.avid-link-mobile',
        '.avid-link-ios',
        '.avid-link-android',
        'avid-link-desktop'
      );
    };
  });

  return (
    <>
      <Head>
        <title>
          {((route?.fields?.metaPageTitle as Field)?.value as string) || 'Avid Marketplace'}
        </title>
        <MetaData sitecore={layoutData.sitecore} />
        <meta charSet="UTF-8" />
        <link rel="icon" href={`${publicUrl}/favicon.ico`} />
      </Head>
      <div
        className={`marketplace-page bg-mp-background-general ${gtWalshiemAvidRegular.variable} ${gtWalshiemAvidLight.variable} ${gtWalshiemAvidMedium.variable} ${gtWalshiemAvidBold.variable}`}
      >
        <div className="px-2.5 md:px-6 m-auto max-w-[1480px] flex flex-col justify-between relative">
          <header className="sticky top-0 z-50 w-full">
            {route && <Placeholder name="jss-header" rendering={route} />}
          </header>
          <main className="mb-auto bg-mp-background-general min-h-screen pt-20">
            {route && <Placeholder name="jss-main" rendering={route} />}
          </main>
        </div>
        <footer className="bg-mp-background-card text-white fixed bottom-0 w-full p-1">
          {route && <Placeholder name="jss-footer" rendering={route} />}
        </footer>
      </div>
      <Script src="https://my.avid.com/scripts/appManager/linkHandler.min.js?v=2"></Script>
      <div>{route && <Placeholder name="jss-extras" rendering={route} />}</div>
    </>
  );
};

export default MarketplaceLayout;
