import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React from 'react';

type HeadingProps = ComponentProps & {
  fields: {
    headingtext: Field<string>;
  };
  params: {
    headingsize: string;
    margintop: string;
    marginbottom: string;
  };
};

const GetClassFromMargin = (MarginType: string, Margin: string): string => {
  if (Margin == null) {
    return '';
  }

  switch (Margin.toLowerCase()) {
    case '0px':
      return ``;
    case '4px':
      return `${MarginType}1`;
    case '8px':
      return `${MarginType}2`;
    case '16px':
      return `${MarginType}4`;
    case '24px':
      return `${MarginType}6`;
    case '32px':
      return `${MarginType}8`;
    case '40px':
      return `${MarginType}10`;
    case '48px':
      return `${MarginType}12`;
    case '64px':
      return `${MarginType}16`;
    case '80px':
      return `${MarginType}20`;
    default:
      return `${MarginType == 'mb-' ? 'mb-10' : ''}`;
  }
};

const Heading = (props: HeadingProps): JSX.Element => {
  const topmargin = props?.params?.margintop
    ? `${GetClassFromMargin('mt-', props.params.margintop)}`
    : '';
  const bottommargin = props?.params?.marginbottom
    ? `${GetClassFromMargin('mb-', props.params.marginbottom)}`
    : 'mb-10';
  const size = props?.params?.headingsize?.toLowerCase() ?? 'h1';
  return (
    <Text
      tag={size}
      className={`${topmargin} ${bottommargin} mx-auto w-full  max-w-content  text-almost-black `}
      field={props?.fields?.headingtext}
    />
  );
};

export default Heading;
