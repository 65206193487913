import { SCREEN_LARGE, SCREEN_SMALL, useScreenSize } from 'lib/hooks/use-screen-size';
import { useEffect, useState } from 'react';
import CarouselDot from './CarouselDot';
import CarouselButtonGroupNoInfinate from './CarouselButtonGroupNoInfinate';
import { default as ResponsiveCarousel } from 'react-multi-carousel';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

type CarouselProps = {
  MaxItemsPerSlideOnDesktop: string;
  MaxItemsPerSlideOnTablet: string;
  MaxItemsPerSlideOnMobile: string;
  desktopSize?: number;
  tabletSize?: number;
  children?: JSX.Element | JSX.Element[] | boolean;
  autoPlay?: boolean;
  autoPlaySpeed?: number;
  infinite?: boolean;
  customDotsGroup?: JSX.Element;
  itemClass?: string;
  buttonGroup?: JSX.Element;
  carouselWrapperClass?: string;
  sliderClass?: string;
  hideDots?: boolean;
  dotListClass?: string;
  renderDotsOutside?: boolean;
  ssgDeviceType?: 'desktop' | 'tablet' | 'mobile';
};
const styles = `
.react-multi-carousel-dot-list li:last-child button {
  margin-right: 0;
}`;

function Carousel(props: CarouselProps): JSX.Element {
  const context = useSitecoreContext();
  const screenSize = useScreenSize();
  const [useDots, setUseDots] = useState(true);

  let childrenCount = 0;
  if (Array.isArray(props.children)) {
    const childrenArray = props.children as JSX.Element[];
    childrenCount = childrenArray.length;
  } else {
    childrenCount = 1;
  }
  const desktopSize = props.desktopSize ?? SCREEN_LARGE;
  const tabletSize = props.tabletSize ?? SCREEN_SMALL;

  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: desktopSize },
      items: parseInt(props.MaxItemsPerSlideOnDesktop),
    },
    tablet: {
      breakpoint: { max: desktopSize, min: tabletSize },
      items: parseInt(props.MaxItemsPerSlideOnTablet),
    },
    mobile: {
      breakpoint: { max: tabletSize, min: 0 },
      items: parseInt(props.MaxItemsPerSlideOnMobile),
    },
  };

  useEffect(() => {
    if (props.hideDots) {
      setUseDots(false);
      return;
    }
    if (screenSize.width >= desktopSize) {
      if (childrenCount <= parseInt(props.MaxItemsPerSlideOnDesktop)) {
        setUseDots(false);
      } else {
        setUseDots(true);
      }

      return;
    }

    if (screenSize.width >= tabletSize) {
      if (childrenCount <= parseInt(props.MaxItemsPerSlideOnTablet)) {
        setUseDots(false);
      } else {
        setUseDots(true);
      }

      return;
    }

    if (childrenCount <= parseInt(props.MaxItemsPerSlideOnMobile)) {
      setUseDots(false);
    } else {
      setUseDots(true);
    }
  }, [
    childrenCount,
    desktopSize,
    props.MaxItemsPerSlideOnDesktop,
    props.MaxItemsPerSlideOnMobile,
    props.MaxItemsPerSlideOnTablet,
    props.hideDots,
    screenSize,
    tabletSize,
    useDots,
  ]);
  return (
    <>
      <style>{styles}</style>
      <div className={`relative ${useDots ? 'pb-10' : ''} ${props.carouselWrapperClass ?? ''}`}>
        <ResponsiveCarousel
          responsive={responsive}
          renderDotsOutside={props.renderDotsOutside ?? true}
          showDots={useDots}
          customDot={props.customDotsGroup ?? <CarouselDot />}
          arrows={false}
          ssr={context?.sitecoreContext?.pageEditing ? false : true}
          customButtonGroup={props.buttonGroup ?? <CarouselButtonGroupNoInfinate />}
          renderButtonGroupOutside={true}
          containerClass="w-full flex"
          sliderClass={`!m-auto ${props.sliderClass}`}
          itemClass={props.itemClass}
          autoPlay={props.autoPlay || false}
          autoPlaySpeed={props.autoPlaySpeed || 3000}
          infinite={props.infinite || false}
          dotListClass={props.dotListClass}
          deviceType={props.ssgDeviceType ?? 'tablet'}
        >
          {props.children}
        </ResponsiveCarousel>
      </div>
    </>
  );
}

export default Carousel;
