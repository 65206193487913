import React, { useEffect } from 'react';
import Head from 'next/head';
import {
  Placeholder,
  getPublicUrl,
  LayoutServiceData,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import MetaData from 'components/foundation/non-sitecore/meta-data/MetaData';
import { useI18n } from 'next-localization';
import { useAppDispatch } from 'lib/store/hooks';
import { navigationActions } from 'lib/navigation/navigation-slice';
import {
  gtWalshiemAvidRegular,
  gtWalshiemAvidLight,
  gtWalshiemAvidMedium,
  gtWalshiemAvidBold,
} from 'lib/fonts/font-loader';

// Prefix public assets with a public URL to enable compatibility with Sitecore Experience Editor.
// If you're not supporting the Experience Editor, you can remove this.
const publicUrl = getPublicUrl();

interface InAppLayoutProps {
  layoutData: LayoutServiceData;
}

const InAppLayout = ({ layoutData }: InAppLayoutProps): JSX.Element => {
  const { route } = layoutData.sitecore;
  const { locale } = useI18n();
  const lang = locale();
  const dispatch = useAppDispatch();
  dispatch(navigationActions.changeLanguage(lang));

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME !== undefined &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL !== undefined &&
      process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT !== undefined &&
      process.env.NEXT_PUBLIC_CLIENT_APM_DISABLED !== 'true'
    ) {
      const apmInit = async (): Promise<void> => {
        const apm = await import('@elastic/apm-rum');
        apm.init({
          serviceName: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME,
          serverUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
          environment: process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT,
          transactionSampleRate: process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
            ? +process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
            : 1,
        });
      };

      apmInit();
    }
  });

  return (
    <>
      <Head>
        <title>{((route?.fields?.pageTitle as Field)?.value as string) || 'Page'}</title>
        <MetaData sitecore={layoutData.sitecore} />
        <meta charSet="UTF-8" />
        <link rel="icon" href={`${publicUrl}/favicon.ico`} />
      </Head>
      <div
        className={`flex flex-col justify-between relative ${gtWalshiemAvidRegular.variable} ${gtWalshiemAvidLight.variable} ${gtWalshiemAvidMedium.variable} ${gtWalshiemAvidBold.variable}`}
      >
        <main className="mb-auto min-h-[100vh]">
          {route && <Placeholder name="jss-main" rendering={route} />}
        </main>
      </div>
    </>
  );
};

export default InAppLayout;
