import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

type RichTextBlockProps = {
  fields: {
    text: Field<string>;
    anchorLinkId: Field<string>;
  };
};
const styles = `
.richTextBl ul {
  list-style: unset;
  padding-left:24px;
},
`;

const RichTextBlock = (props: RichTextBlockProps): JSX.Element => {
  return (
    <>
      <style>{styles}</style>
      <RichText
        field={props?.fields?.text}
        tag="div"
        className="rte richTextBl max-w-content mx-auto text-base-darker  [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:mb-10 [&_a]:text-primary p-1 px-6 sm:mb-10 md:mb-20 "
        {...(props?.fields?.anchorLinkId?.value ? { id: props?.fields?.anchorLinkId?.value } : {})}
      />
    </>
  );
};

export default RichTextBlock;
