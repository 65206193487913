import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { CartItemDetails, GetCartItemsData } from 'components/cart/cart-models';
import { updateCartItem, deleteCartItem } from 'components/cart/cart-slice';
import { useI18n } from 'next-localization';
import {
  AnalyticsProductItem,
  getProductCartIndex,
  pushAddToCart,
  pushRemoveFromCart,
} from 'lib/google-analytics/commerce';

import BaseItemQuantityNudger from './BaseItemQuantityNudger';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useEffect, useMemo, useState } from 'react';

type CartItemQuantityNudgerProps = {
  cartItem: CartItemDetails;
  quantityLimits:
    | {
        productId: string;
        min: number;
        max: number;
      }
    | undefined;
};

const CartItemQuantityNudger = (props: CartItemQuantityNudgerProps): JSX.Element => {
  const { t } = useI18n();
  const cart = useAppSelector((state) => state.cart);
  const cartItem = props.cartItem;
  const dispatch = useAppDispatch();
  const cartUpdateItemStatus = useAppSelector((state) => state.cart.cartUpdateItemStatus);
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const [quantity, setQuantity] = useState<number>(parseInt(cartItem.quantity));

  const onClickRemoveItemHandler = async (cartItemId: string): Promise<void> => {
    dispatch(deleteCartItem({ cartItemId, bearerToken: bearerToken }));

    if (cart.currencyIsoCode == null || cart.cartSummaryData == null) {
      return;
    }

    pushRemoveFromCart(
      cart.currencyIsoCode,
      parseFloat(cartItem.salesPrice).toString(),
      convertToAnalyticsItem(cartItem, cart.cartItemsData, cartItem.quantity)
    );
  };

  const onChangeQuantityHandler = (newQuantity: number): void => {
    if (cart.currencyIsoCode == null || cart.cartSummaryData == null) {
      return;
    }

    if (newQuantity < 1) {
      setQuantity(0);
      return;
    }
    const oldQantity = quantity;
    setQuantity(newQuantity);

    dispatch(
      updateCartItem({
        cartItemId: cartItem.cartItemId,
        quantity: newQuantity,
        bearerToken: bearerToken,
      })
    )
      .unwrap()
      .then((resp) => {
        if (resp == null) {
          setQuantity(oldQantity);
          return;
        }
        if (newQuantity > quantity) {
          pushAddToCart(
            cart.currencyIsoCode ?? '',
            (
              parseFloat(cart.cartSummaryData?.totalProductAmountAfterAdjustments ?? '') +
              parseFloat(cartItem.salesPrice)
            ).toString(),
            convertToAnalyticsItem(cartItem, cart.cartItemsData)
          );
        } else {
          pushRemoveFromCart(
            cart.currencyIsoCode ?? '',
            parseFloat(cartItem.salesPrice).toString(),
            convertToAnalyticsItem(cartItem, cart.cartItemsData)
          );
        }
        const cartItemUpdate = resp.data as CartItemDetails;
        setQuantity(parseInt(cartItemUpdate.quantity));
      })
      .catch((error) => {
        console.log(`Can't update Cart: ${error.message}`);
        setQuantity(quantity);
      });
  };

  const convertToAnalyticsItem = (
    cartItem: CartItemDetails,
    cartData: GetCartItemsData | null,
    itemQuantity?: string
  ): AnalyticsProductItem => {
    const index = getProductCartIndex(cartItem.productId, cartData);

    return {
      id: cartItem.productId,
      sku: cartItem.productId,
      name: cartItem.productDetails.name,
      price: cartItem.listPrice.toString(),
      discount: (parseInt(cartItem.listPrice) - parseInt(cartItem.salesPrice)).toString(),
      brand: cartItem.productDetails.brand,
      variant: cartItem.productDetails.sku,
      index: index.toString(),
      ...(itemQuantity && { quantity: itemQuantity }),
      itemCategory: cartItem.productCategoryPaths.primary?.at(-1)?.name,
      itemCategory2: cartItem.productCategoryPaths.path1?.at(-1)?.name,
      itemCategory3: cartItem.productCategoryPaths.path2?.at(-1)?.name,
      itemCategory4: cartItem.productCategoryPaths.path3?.at(-1)?.name,
      productSku: cartItem.productDetails.sku,
    };
  };

  useEffect(() => {
    setQuantity(parseInt(props.cartItem.quantity));
  }, [props.cartItem.quantity]);

  return (
    <div className="flex space-x-3">
      <p
        className="text-sm text-primary hover:cursor-pointer underline flex items-center"
        onClick={(): Promise<void> => onClickRemoveItemHandler(cartItem.cartItemId)}
      >
        {t('Cart_Remove')}
      </p>
      <BaseItemQuantityNudger
        key={`nudger${quantity}`}
        passValueToParentFunction={onChangeQuantityHandler}
        disableButton={cartUpdateItemStatus === 'loading'}
        quantity={quantity}
        minValue={props.quantityLimits?.min ?? undefined}
        maxValue={props.quantityLimits?.max ?? undefined}
        isReadOnly={false}
      />
    </div>
  );
};

export default CartItemQuantityNudger;
