import {
  ImageField,
  NextImage,
  Text,
  isEditorActive,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { PricingCardDataSource, PricingCardProps } from './pricing-card-props';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { addCartItem } from 'components/cart/cart-slice';
import Spinner from 'components/spinner/Spinner';
import {
  getProductCartIndex,
  pushAddToCart,
  pushViewItem,
  pushCoveoAddCartItem,
} from 'lib/google-analytics/commerce';
import { usePrice } from 'lib/commerce/product/price-hook';
import { useEffect, useMemo, useState } from 'react';
import { getProductDetails } from 'lib/commerce/product/product-slice';
import { ParsedUrlQueryInput } from 'querystring';
import { useRouter } from 'next/router';
import { getSitecoreProductDetails } from 'lib/commerce/product/product-api';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import InlineSpinner from 'components/spinner/InlineSpinner';
import DownloadSection from '../../components/pricing-card/DownloadSection';
import { useI18n } from 'next-localization';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

const TestPricingCard = (props: PricingCardProps<PricingCardDataSource>): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const query: string | ParsedUrlQueryInput = router.query;
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const productItem = props.fields?.data?.contextItem?.product?.targetItems[0] ?? null;
  const productId = (query.id as string) ?? productItem?.productId.value;
  const price = usePrice(productId, currencyIsoCode);
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const cart = useAppSelector((state) => state.cart);
  const contextLanguage = 'en';

  type ImageDetails = {
    jsonValue: ImageField;
    value: string;
  };

  const [imgDetails, setImgDetails] = useState<ImageDetails | null>(null);
  //const [productPricing, setProductPricing] = useState<ProductPricingData | null>(null);
  const [productName, setProductName] = useState<string | null>(null);
  //const [productUrl, setProductUrl] = useState<string | null>(null);

  useEffect(() => {
    if (price == null || productItem == null || props.stopEvents || currencyIsoCode == null) {
      return;
    }

    const fetchProductDetails = async (): Promise<void> => {
      const result = await getSitecoreProductDetails(productId, contextLanguage);

      if (
        result != null &&
        result.length > 0 &&
        result[0].productName?.value != undefined &&
        result[0].productName.value.length > 0
      ) {
        result[0].productImage !== undefined && setImgDetails(result[0].productImage);
        setProductName(result[0].productName.value);
        //setProductUrl(result[0]?.productDetailsPageLink?.jsonValue?.value?.href as string);
      }
    };

    if (imgDetails === null && productName === null) {
      fetchProductDetails();
    }

    pushViewItem(currencyIsoCode, price.unitPrice.toString(), {
      id: productItem.productId.value,
      sku: productItem.productCode.value,
      name: productItem.productName.value,
      price: price.listPrice.toString(),
      discount: (price.listPrice - price.unitPrice).toString(),
      brand: productItem.brand.value,
      variant: productItem.term?.value ?? 'N/A',
      itemCategory: productItem.primaryCategory?.targetItems[0]?.categoryName?.value,
      itemCategory2: productItem.additionalCategories?.targetItems[0]?.categoryName?.value,
      itemCategory3: productItem.additionalCategories?.targetItems[1]?.categoryName?.value,
      itemCategory4: productItem.additionalCategories?.targetItems[2]?.categoryName?.value,
    });
  }, [currencyIsoCode, imgDetails, price, productId, productItem, productName, props.stopEvents]);

  const addToCartHandler = (): void => {
    dispatch(addCartItem({ sku: productId, quantity: 1, bearerToken: bearerToken }));
    if (
      price == null ||
      currencyIsoCode == null ||
      productItem == null ||
      cart.cartSummaryData == null
    ) {
      return;
    }

    const index = getProductCartIndex(productId, cart.cartItemsData);
    pushAddToCart(currencyIsoCode, price.unitPrice.toString(), {
      id: productId,
      sku: productItem.productCode.value,
      name: productItem.productName.value,
      brand: productItem.brand.value,
      price: price.listPrice.toString(),
      discount: (price.listPrice - price.unitPrice).toString(),
      variant: productItem.term?.value ?? 'N/A',
      index: index.toString(),
      itemCategory: productItem.primaryCategory?.targetItems[0]?.categoryName?.value,
      itemCategory2: productItem.additionalCategories?.targetItems[0]?.categoryName?.value,
      itemCategory3: productItem.additionalCategories?.targetItems[1]?.categoryName?.value,
      itemCategory4: productItem.additionalCategories?.targetItems[2]?.categoryName?.value,
    });
    onClickSendCoveoAdd(productId, productItem.productName.value, price.listPrice, currencyIsoCode);
  };

  const onClickSendCoveoAdd = async (
    sku: string,
    name: string,
    price: number,
    currency: string
  ): Promise<void> => {
    const productDetails = await dispatch(getProductDetails(sku)).unwrap();
    if (productDetails == null) return;

    const categoryPaths = productDetails?.data?.productCategoryPaths?.primary;

    const productCategory =
      categoryPaths != null ? categoryPaths[categoryPaths.length - 1]?.name : '';

    pushCoveoAddCartItem(sku, name, price, currency, productCategory);
  };

  const pricingCard = props;
  const productPricing = price;
  const loading = price == null;
  const frequencyText = '';

  const editorActive = isEditorActive();
  const hasBadge =
    pricingCard.fields?.data?.datasource?.badge?.jsonValue?.value != null &&
    pricingCard.fields?.data?.datasource?.badge?.jsonValue?.value.length > 0;

  const hasSubTitle =
    pricingCard.fields?.data?.datasource?.subTitle?.jsonValue?.value != null &&
    pricingCard.fields?.data?.datasource?.subTitle?.jsonValue?.value.length > 0;

  const hasFooter =
    pricingCard.fields?.data?.datasource?.footer?.jsonValue?.value != null &&
    pricingCard.fields?.data?.datasource?.footer?.jsonValue?.value.length > 0;

  const onSale = useMemo(() => {
    if (productPricing == null) {
      return false;
    }

    return productPricing.unitPrice != productPricing.listPrice;
  }, [productPricing]);

  const isFree = useMemo(() => {
    if (productPricing == null) {
      return false;
    }

    return productPricing.unitPrice == 0;
  }, [productPricing]);

  if (productItem == null) {
    if (editorActive) {
      return <div className="editor-message">Please select a product on the page item</div>;
    }

    return <></>;
  }

  if (pricingCard.fields?.data?.datasource == null) {
    if (editorActive) {
      return <div className="editor-message">Please select a datasource.</div>;
    }

    return <></>;
  }

  return (
    <>
      {pricingCard.mini && (
        <div className="m-2 flex justify-between items-center w-full md:w-fit pr-3">
          <div className="flex items-center justify-start space-x-2 w-full md:w-fit">
            {onSale && (
              <div className="hidden lg:block line-through text-base-dark text-[0.8125rem] tracking-[0.2em]">
                {t(`Currency_Symbol_${productPricing?.currencyIsoCode}`)}
                {productPricing?.listPrice}
              </div>
            )}

            <div className="hidden lg:block text-[1.25rem] tracking-[0.2em]">
              {isFree && <span className="mb-2">{t('PricingCard_Free')}</span>}
              {!isFree && (
                <>
                  {t(`Currency_Symbol_${productPricing?.currencyIsoCode}`)}
                  {productPricing?.unitPrice}
                </>
              )}
            </div>
            <div className="text-right flex-grow">
              <button
                className="btn-primary ml-auto px-3 py-1 text-[1rem]"
                onClick={addToCartHandler}
              >
                <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
              </button>
            </div>
          </div>
        </div>
      )}
      {!pricingCard.mini && (
        <div className="relative grid justify-center grid-cols-1 mx-auto md:grid-cols-3 2xl:max-w-4xl shadow-card">
          <div className="rounded-t md:rounded-l md:rounded-tr-none overflow-hidden  flex flex-col md:col-span-2 aspect-[4/3] relative bg-base-light">
            {imgDetails?.jsonValue.value?.src !== undefined &&
            imgDetails?.jsonValue.value?.src?.length > 0 ? (
              <NextImage
                field={imgDetails?.jsonValue}
                className=""
                width={1200}
                height={762}
                alt={`${productName} image`}
              />
            ) : (
              <NextImage
                field={pricingCard.fields?.data?.datasource?.image?.jsonValue}
                className={`w-full`}
              />
            )}
          </div>
          <div className="text-center p-6 bg-base-lightest rounded-b md:rounded-r md:rounded-l-none md:flex md:flex-col md:justify-center md:px-8">
            <h4 className="font-bold mb-4">{productName ?? 'NA In Sitecore'}</h4>
            {(hasBadge || editorActive) && (
              <div className="mx-auto mb-5 w-fit bg-info-light text-info rounded-xl px-2 py-1 font-bold uppercase text-sm tracking-wide">
                <span>
                  <Text field={pricingCard.fields.data.datasource.badge.jsonValue} />
                </span>
              </div>
            )}

            {productPricing != null && (
              <>
                {onSale && (
                  <div className="line-through text-base-dark tracking-[0.55rem] lg:text-xl">
                    {t(`Currency_Symbol_${productPricing?.currencyIsoCode}`)}
                    {productPricing?.listPrice}
                  </div>
                )}

                <div className="accent-1 tracking-[0.55rem] mb-1 uppercase">
                  {isFree && <div className="mb-2">{t('PricingCard_Free')}</div>}
                  {!isFree && (
                    <>
                      {t(`Currency_Symbol_${productPricing?.currencyIsoCode}`)}
                      {productPricing.unitPrice}
                    </>
                  )}
                </div>
                {}
                {!isFree && (
                  <div className="text-xs mb-4 tracking-[0.16rem] uppercase lg:text-[0.8125rem]">
                    {productPricing.currencyIsoCode}
                    {frequencyText}
                  </div>
                )}
              </>
            )}

            {loading && (
              <div className="mb-2">
                <InlineSpinner />
              </div>
            )}

            {(hasSubTitle || editorActive) && (
              <div className="mb-5">
                <Text tag="span" field={pricingCard.fields.data.datasource.subTitle.jsonValue} />
              </div>
            )}

            <div className="flex flex-col">
              {productItem.children?.results?.length > 0 && (
                <DownloadSection
                  MyAvidLinks={false}
                  DownloadOptions={productItem.children.results}
                />
              )}
              {productItem.children?.results?.length <= 0 && (
                <>
                  <button
                    className="btn-primary ml-auto w-full px-8 relative"
                    onClick={addToCartHandler}
                  >
                    <Text field={props.fields?.data?.datasource?.buttonLabel?.jsonValue} />
                    {cart.cartAddItemStatus == 'loading' && (
                      <Spinner height={15} width={3} margin={3} />
                    )}
                  </button>
                  <br></br>
                  <br></br>

                  <a
                    className="btn-outline ml-auto px-3 py-1 text-[1rem] w-full text-center"
                    href={
                      process.env.NEXT_PUBLIC_SALESFORCE_BASE_URL +
                      '/product/price?webstoreName=Avid.com&productId=' +
                      productId +
                      '&currencyIsoCode=USD'
                    }
                    target="_blank"
                  >
                    Check SF API Response
                  </a>
                </>
              )}

              <NextLink
                className="mt-3 btn-link hover:bg-transparent"
                field={pricingCard.fields?.data?.datasource?.secondaryCta?.jsonValue}
              />
            </div>

            {(hasFooter || editorActive) && (
              <div className="mt-5 text-sm">
                <Text tag="span" field={pricingCard.fields.data.datasource.footer.jsonValue} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default withDatasourceCheck()(TestPricingCard);
