import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Field,
  ImageField,
  PlaceholderComponentProps,
  Text,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { BrightcoveVideoProps } from 'components/video/brightcove-video-props';
import { YoutubeVideoProps } from 'components/video/youtube-video-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import BaseVideoModal from 'components/video/BaseVideoModal';
import { ComponentProps } from 'lib/component-props';

type StaticHeroBannerProps = ComponentProps &
  PlaceholderComponentProps & {
    fields: {
      Heading: Field<string>;
      BackgroundImage: ImageField;
      CtaButtonLabel: Field<string>;
      videos: (BrightcoveVideoProps | YoutubeVideoProps)[];
    };
    bannerSearch: JSX.Element;
  };

const StaticHeroBannerContents = (props: StaticHeroBannerProps): JSX.Element => {
  const mediumVariant = props?.params?.Size == 'medium';
  const isEditing = isEditorActive();

  return (
    <div
      className={`w-full relative flex justify-center items-center aspect-[1.25/1] sm:mb-10 lg:mb-20 ${
        mediumVariant ? 'md:aspect-[4/1]' : 'md:aspect-[3/1]'
      }`}
    >
      <NextImage
        className={`object-cover ${isEditing ? 'max-h-full' : ''}`}
        field={props.fields?.BackgroundImage}
        layout="fill"
      />

      <div className="bg-gradient-to-b from-avidgray100 absolute w-full h-full top-0 left-0"></div>

      <div className="relative text-center w-full">
        <h1
          className={`text-white shadow-almost-black text-shadow ${
            mediumVariant ? 'display-3' : 'display-1'
          } `}
        >
          <Text field={props.fields?.Heading} />
        </h1>

        {props.fields?.videos?.[0] && (
          <div className="btn-feature inline-flex items-center mt-6 space-x-2">
            <FontAwesomeIcon icon="circle-play" className="text-[20px]" />
            <div className="body">
              <Text field={props.fields?.CtaButtonLabel} />
            </div>
          </div>
        )}

        {props.bannerSearch}
      </div>
    </div>
  );
};

const StaticHeroBanner = (props: StaticHeroBannerProps): JSX.Element => {
  const video = props.fields?.videos?.[0];

  if (video) {
    return (
      <BaseVideoModal
        video={video}
        openCtaClassName="w-full"
        openCtaChildren={<StaticHeroBannerContents {...props} />}
      />
    );
  } else return <StaticHeroBannerContents {...props} />;
};

export default withPlaceholder({ placeholder: 'static-hero-banner-search', prop: 'bannerSearch' })(
  StaticHeroBanner
);
