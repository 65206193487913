import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type CallToActionGroupProps = ComponentProps & {
  params: {
    Orientation: string;
    Alignment: string;
    TopMargin: string;
    BottomMargin: string;
  };
};

const GetClassFromOrientation = (orientation: string): string => {
  if (orientation == null) {
    return '';
  }

  switch (orientation.toLowerCase()) {
    case 'row':
      return 'flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-6';
    case 'column':
      return 'flex-col';
    default:
      return 'flex-row';
  }
};

const GetClassFromAlignment = (alignment: string): string => {
  if (alignment == null) {
    return '';
  }

  switch (alignment.toLowerCase()) {
    case 'left':
      return 'justify-start items-start';
    case 'center':
      return 'justify-center items-start';
    case 'right':
      return 'justify-end items-end';
    default:
      return 'justify-start items-start';
  }
};

const GetClassFromTopMargin = (topMargin: string): string => {
  if (topMargin == null) {
    return '';
  }

  switch (topMargin.toLowerCase()) {
    case '4px':
      return 'mt-1';
    case '8px':
      return 'mt-2';
    case '16px':
      return 'mt-4';
    case '24px':
      return 'mt-6';
    case '32px':
      return 'mt-8';
    case '40px':
      return 'mt-10';
    case '48px':
      return 'mt-12';
    case '64px':
      return 'mt-16';
    case '80px':
      return 'mt-20';
    default:
      return 'mt-1';
  }
};

const GetClassFromBottomMargin = (bottomMargin: string): string => {
  if (bottomMargin == null) {
    return '';
  }

  switch (bottomMargin.toLowerCase()) {
    case '4px':
      return 'mb-1';
    case '8px':
      return 'mb-2';
    case '16px':
      return 'mb-4';
    case '24px':
      return 'mb-6';
    case '32px':
      return 'mb-8';
    case '40px':
      return 'mb-10';
    case '48px':
      return 'mb-12';
    case '64px':
      return 'mb-16';
    case '80px':
      return 'mb-20';
    default:
      return 'mb-1';
  }
};

const CallToActionGroup = (props: CallToActionGroupProps): JSX.Element => {
  const orientationClass = GetClassFromOrientation(props?.params?.Orientation);
  const alignmentClass = GetClassFromAlignment(props?.params?.Alignment);
  const topMarginClass = GetClassFromTopMargin(props?.params?.TopMargin);
  const bottomMarginClass = GetClassFromBottomMargin(props?.params?.BottomMargin);

  return (
    <div
      className={`flex px-3 ${orientationClass} ${alignmentClass} ${topMarginClass} ${bottomMarginClass}`}
    >
      <Placeholder name="jss-call-to-action-group" rendering={props.rendering} />
    </div>
  );
};

export default CallToActionGroup;
