import { useI18n } from 'next-localization';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ProductFeatureItem } from 'components/foundation/commerce/models/product-features';
import MultiProductTableCardFeatures from './MultiProductTableCardFeatures';

type MultiProductTableCardProps = {
  totalVariations: number;
  title: string;
  titleTag: 'h2' | 'h3' | 'h4' | 'h5' | string;
  subtitle?: Field<string>;
  listPrice: number;
  unitPrice: number;
  currencyCode: string;
  frequencyLabel: string;
  featuredTheme: string;
  featuredText: Field<string>;
  features: ProductFeatureItem[];
  featuresHeading: Field<string>;
  isEnterprise?: boolean | null;
  enterpriseDescription?: string | null;
  children?: JSX.Element | JSX.Element[] | boolean;
  badgeSection?: JSX.Element | JSX.Element[] | boolean | null;
};

const MultiProductTableCard = (props: MultiProductTableCardProps): JSX.Element => {
  const { t } = useI18n();

  const {
    totalVariations,
    listPrice,
    unitPrice,
    currencyCode,
    frequencyLabel,
    featuredTheme,
    featuredText,
    badgeSection,
    title,
    subtitle,
    features,
    featuresHeading,
    titleTag,
    isEnterprise,
    enterpriseDescription,
  } = props;

  const TitleTagComponent = titleTag as keyof JSX.IntrinsicElements;

  const formatPrice = (num: number): string => (num % 1 === 0 ? num.toString() : num.toFixed(2));

  return (
    <div
      className={`multi-product-table-card m-4 border border-base-medium-dark sm:max-w-[350px] rounded !overflow-hidden shadow-card xl:mx-auto pt-auto text-almost-black flex flex-col items-center basis-0 flex-grow relative ${
        totalVariations < 5
          ? `xl:rounded-none xl:shadow-none ${
              totalVariations === 1 ? 'xl:max-w-[400px]' : 'xl:max-w-none'
            }`
          : 'xl:border-base-light'
      }
      ${totalVariations === 2 && 'md:mx-0 md:max-w-[400px] md:rounded-none md:shadow-none'}
      `}
    >
      <div className="flex flex-col items-center w-full">
        <span
          className={`-z-10 py-[6px] w-full text-center text-white uppercase accent-3 tracking-[0.2em] min-h-[31px] ${
            !featuredText.value && 'invisible'
          } ${featuredTheme == 'primary' && 'bg-secondary'} ${
            featuredTheme == 'secondary' && 'bg-primary'
          }`}
        >
          {featuredText.value ? <Text tag="span" field={featuredText} /> : 'n/a'}
        </span>
        <div className="flex flex-col items-center px-4 w-full">
          <TitleTagComponent className="h4 mt-5 font-bold text-center xl:min-h-[58px]">
            {title}
          </TitleTagComponent>
          {subtitle != null && subtitle.value.length > 0 && (
            <span className="px-4 text-center mt-1 xl:min-h-[48px]">
              <Text field={subtitle} />
            </span>
          )}
          {badgeSection}
          {!isEnterprise && (
            <>
              <h5
                className={`text-base-dark line-through text-[1.25rem] font-normal tracking-[0.2em] xl:mt-2 ${
                  unitPrice === listPrice && 'invisible'
                }`}
              >
                {t(`Currency_Symbol_${currencyCode}`)}
                {formatPrice(unitPrice)}
              </h5>
              {listPrice <= 0 && (
                <>
                  <h5 className="accent-1 tracking-[0.1em]">{t('PricingCard_Free')}</h5>
                  <h5 className="accent-3 tracking-[0.2em] uppercase mb-4">&nbsp;</h5>
                </>
              )}
              {listPrice > 0 && (
                <>
                  <h5
                    className={`accent-1 tracking-[0.2em] ${
                      featuredText.value && featuredTheme === 'primary' && 'text-secondary'
                    }`}
                  >
                    {t(`Currency_Symbol_${currencyCode}`)}
                    {formatPrice(listPrice)}
                  </h5>
                  <h5 className="accent-3 tracking-[0.2em] uppercase mb-4">
                    {currencyCode}
                    {frequencyLabel}
                  </h5>
                </>
              )}
            </>
          )}
          {isEnterprise && (
            <div className="px-6 py-5 lg:py-[30px] text-center font-avidwalsheim text-lg font-medium">
              {enterpriseDescription}
            </div>
          )}

          {props.children}
        </div>
      </div>

      {features && features.length > 0 && (
        <MultiProductTableCardFeatures
          features={features}
          featureHeading={featuresHeading}
          isFeatured={featuredText.value.length > 0 && featuredTheme === 'primary'}
        />
      )}
    </div>
  );
};

export default MultiProductTableCard;
