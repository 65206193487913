import {
  Field,
  Text,
  RichText,
  LinkField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { changeCurrency } from 'components/cart/cart-slice';
import { ComponentProps } from 'lib/component-props';
import { useI18n } from 'next-localization';
import { useEffect, useMemo, useState } from 'react';
import { default as NextLink } from 'components/foundation/non-sitecore/NextLink';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useWebStoreCurrencies } from 'lib/commerce/webstore/webstore-hooks';
import DropdownMenu from 'components/dropdown/shop/DropdownMenu';
import ExpressPayments from '../../components/cart/shop/ExpressPayments';
import { setSelectedSource } from 'components/checkout/checkout-slice';
import CartItem from '../../components/cart/shop/CartItem';
import { useCartData } from 'components/cart/cart-hooks';
import Spinner from 'components/spinner/Spinner';
import { AnalyticsProductItem, pushViewCart } from 'lib/google-analytics/commerce';
import CouponDetails from '../../components/cart/shop/CouponDetails';
import { useSession } from 'next-auth/react';
import { getBearerToken, getCheckoutAuthUrl } from 'lib/authentication/account-provider';

type CartRuleSitecore = {
  minCartQuantity: {
    value: string;
  };
  maxCartQuantity: {
    value: string;
  };
  product: {
    targetItems: {
      productId: {
        value: string;
      };
    }[];
  };
};

type CartRuleSimplified = {
  productId: string;
  min: number;
  max: number;
};

type SummaryProps = ComponentProps & {
  fields: {
    data?: {
      datasource?: {
        title: Field<string>;
        link: {
          jsonValue: LinkField;
        };
        continueShoppingLink: {
          jsonValue: LinkField;
        };
        taxNotCalculated: Field<string>;
        cartRules: {
          targetItems: CartRuleSitecore[];
        };
      };
    };
  };
};

const CartSummary = (props: SummaryProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const currencies = useWebStoreCurrencies();
  const currencyOptions = currencies.map((currency) => {
    return { value: currency, label: `${currency} ${t(`Currency_Symbol_${currency}`)}` };
  });

  const cartItemData = useCartData();
  const cart = useAppSelector((state) => state.cart);
  const cartItemStatus = cart?.cartItemsDataStatus;
  const cartItems = cartItemData?.cartItems;
  const cartSummary = cartItemData?.cartSummary;
  const currencyIsoCode = cart?.currencyIsoCode;
  const applyCouponStatus = cart?.applyCouponStatus;
  const [lastTotal, setLastTotal] = useState(-1);
  const [viewCartEventFired, setViewCartEventFired] = useState(false);

  const hasCartItems = cartItems != null && cartItems.length > 0;

  const getCartItemsLimits = (sitecoreArray: CartRuleSitecore[]): CartRuleSimplified[] => {
    const limitsList: CartRuleSimplified[] = [];

    sitecoreArray.forEach((item) => {
      const min = item.minCartQuantity.value ? Number(item.minCartQuantity.value) : 1;
      const max = item.maxCartQuantity.value ? Number(item.maxCartQuantity.value) : 99;

      item.product.targetItems.forEach((productItem) => {
        limitsList.push({
          productId: productItem.productId.value,
          min,
          max,
        });
      });
    });
    return limitsList;
  };

  const cartItemsLimits = getCartItemsLimits(
    props.fields?.data?.datasource?.cartRules?.targetItems ?? []
  );

  useEffect(() => {
    dispatch(setSelectedSource(null));
  }, [dispatch]);

  useEffect(() => {
    if (cartItems == null || currencyIsoCode == null || cartSummary == null || viewCartEventFired) {
      return;
    }

    const cartTotal = parseFloat(cartSummary.totalProductAmountAfterAdjustments);
    if (lastTotal == cartTotal) {
      return;
    }

    pushViewCart(
      currencyIsoCode,
      cartTotal.toString(),
      cartItems.map((cartItem, index): AnalyticsProductItem => {
        return {
          id: cartItem.cartItem.productId,
          sku: cartItem.cartItem.productId,
          name: cartItem.cartItem.productDetails.name,
          brand: cartItem.cartItem.productDetails.brand,
          price: cartItem.cartItem.listPrice,
          discount: (
            parseFloat(cartItem.cartItem.listPrice) - parseFloat(cartItem.cartItem.salesPrice)
          ).toString(),
          variant: cartItem.cartItem.productDetails.fields.XC_SubscriptionType__c,
          index: index.toString(),
          quantity: cartItem.cartItem.quantity,
          itemCategory: cartItem.cartItem.productCategoryPaths.primary?.at(-1)?.name,
          itemCategory2: cartItem.cartItem.productCategoryPaths.path1?.at(-1)?.name,
          itemCategory3: cartItem.cartItem.productCategoryPaths.path2?.at(-1)?.name,
          itemCategory4: cartItem.cartItem.productCategoryPaths.path3?.at(-1)?.name,
          productSku: cartItem.cartItem.productDetails.sku,
        };
      })
    );

    setLastTotal(cartTotal);
    setViewCartEventFired(true);
  }, [cartSummary, cartItems, currencyIsoCode, lastTotal, viewCartEventFired]);

  const onChangeCurrencyHandler = (currency: string): void => {
    if (currency == currencyIsoCode) {
      return;
    }

    dispatch(changeCurrency({ currencyIsoCode: currency, bearerToken: bearerToken }));
  };

  const hasLink = props.fields.data?.datasource?.link?.jsonValue.value?.href ? true : false;
  const fullLink =
    hasLink && props.fields.data?.datasource?.link?.jsonValue.value.href?.includes('http')
      ? props.fields.data?.datasource?.link?.jsonValue.value.href
      : `${
          process.env.MYAVID_RETURN_URL_HOST || process.env.VERCEL_URL || process.env.PUBLIC_URL
        }${props.fields.data?.datasource?.link?.jsonValue.value.href}`;
  const authLink = getCheckoutAuthUrl(fullLink);

  return (
    <>
      <Text
        tag="h2"
        field={props.fields.data?.datasource?.title}
        className="uppercase text-center mb-4 text-almost-black tracking-[0.28em]"
      />
      <div
        id="checkout-summary"
        className="summary relative w-full max-w-[450px] mx-auto border border-base-normal p-6 rounded bg-white mb-[73px] md:mb-16 2xl:mb-0 overflow-hidden"
      >
        {currencyIsoCode && (
          <DropdownMenu
            key={currencyIsoCode}
            defaultValue={{
              value: currencyIsoCode,
              label: `${currencyIsoCode} ${t(`Currency_Symbol_${currencyIsoCode}`)}`,
            }}
            options={currencyOptions}
            passValueToParentFunction={onChangeCurrencyHandler}
            className="mb-6"
            id="currency-drop-down"
          />
        )}
        {(cartItemStatus != 'succeeded' || applyCouponStatus === 'loading') && <Spinner />}
        {cartItems != null &&
          cartSummary != null &&
          cartItems.map((item) => {
            return (
              <CartItem
                key={item.cartItem.cartItemId}
                cartItem={item.cartItem}
                currencyIsoCode={cartSummary.currencyIsoCode}
                quantityLimits={cartItemsLimits.find(
                  (el) => el.productId === item.cartItem.productId
                )}
                editable
              />
            );
          })}
        <CouponDetails isEditable={true} />
        <div className="wrapper flex flex-col items-end">
          <div className="flex justify-between w-full mb-4">
            <h3 className="font-normal text-2xl">{t('Cart_Total')}</h3>
            <h3 className="font-bold text-2xl">
              {t(`Currency_Symbol_${cartSummary?.currencyIsoCode}`)}
              {cartSummary != null ? cartSummary.totalProductAmountAfterAdjustments : 0}
            </h3>
          </div>

          <a
            href={authLink}
            id="cart-proceed-btn"
            className={`w-full btn-primary text-center ${
              !hasCartItems ? 'pointer-events-none bg-base-normal' : ''
            }`}
          >
            {t('Cart_Proceed')}
          </a>

          {hasCartItems && (
            <div className="w-full">
              <ExpressPayments />
            </div>
          )}

          {props.fields.data?.datasource?.continueShoppingLink.jsonValue && (
            <NextLink
              field={props.fields.data?.datasource?.continueShoppingLink.jsonValue}
              className="mx-auto"
            >
              <div className="capitalize btn-link-underline underline p-0 mt-4">
                <span>{t('Cart_ContinueShopping')}</span>
              </div>
            </NextLink>
          )}
          <RichText
            field={props.fields.data?.datasource?.taxNotCalculated}
            className="rte mt-6 text-center text-sm text-neutral-medium"
          />
        </div>
      </div>
    </>
  );
};

export default withDatasourceCheck()<SummaryProps>(CartSummary);
