import { buildSearchEngine } from '@coveo/atomic-react';
import { useMemo } from 'react';
import { pushCoveoSearchEvent } from 'lib/google-analytics/coveo-site-search';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

import dynamic from 'next/dynamic';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';

const EventCenterBaseSearchResults = dynamic(
  () => import('../../components/search/EventBaseSearchResults')
);

type QueryCustomSearchProps = ComponentProps & {
  fields: {
    coveoQuery: ReferenceStringItem;
  };
};

const Default = (props: QueryCustomSearchProps): JSX.Element => {
  const coveoQuery = props.fields?.coveoQuery?.fields != null;

  const searchEngine = useMemo(() => {
    return buildSearchEngine({
      configuration: {
        accessToken:
          process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
            ? process.env.COVEO_ACCESS_TOKEN
            : '',
        organizationId:
          process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
            ? process.env.COVEO_ORGANIZATION
            : '',
        search: {
          pipeline:
            process.env.COVEO_PIPELINE && process.env.COVEO_PIPELINE.length > 0
              ? process.env.COVEO_PIPELINE
              : '',
          searchHub: 'AvidComGlobalSearch',
        },
        analytics: {
          analyticsClientMiddleware: (...[, payload]) => {
            pushCoveoSearchEvent(payload);
            return payload;
          },
        },
        preprocessRequest: (request, clientOrigin) => {
          if (!coveoQuery) {
            return request;
          }

          if (clientOrigin == 'searchApiFetch') {
            const body = JSON.parse(request.body?.toString() ?? '');
            body.aq = props.fields?.coveoQuery?.fields.value.value;
            request.body = JSON.stringify(body);
          }

          return request;
        },
      },
    });
  }, [coveoQuery, props.fields?.coveoQuery?.fields.value.value]);

  const itemListName = 'Custom Search Results';
  const itemListId = `CustomSearchResults_0`;

  return (
    <>
      <EventCenterBaseSearchResults
        listId={itemListId}
        listName={itemListName}
        searchEngine={searchEngine}
        leftRailBottomConntent={
          <Placeholder name="custom-search-rail-bottom" rendering={props.rendering} />
        }
      />
    </>
  );
};

export default withDatasourceCheck()(Default);
