import {
  AtomicResultList,
  AtomicSearchInterface,
  buildSearchEngine,
  buildContext,
  AtomicSearchBox,
  AtomicPager,
  AtomicSortDropdown,
  AtomicSortExpression,
  AtomicLayoutSection,
  AtomicDidYouMean,
  AtomicSearchBoxQuerySuggestions,
  AtomicSearchBoxRecentQueries,
  AtomicSearchBoxInstantResults,
  AtomicSearchLayout,
  AtomicFacetManager,
  AtomicQuerySummary,
  AtomicTimeframeFacet,
  AtomicResultSectionExcerpt,
  AtomicResultSectionTitle,
  AtomicResultDate,
  AtomicResultSectionBadges,
  AtomicResultText,
  Result,
} from '@coveo/atomic-react';

import SearchTitle from 'components/atomic-components/SearchTitle';
import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import NoResults from 'components/atomic-components/NoResults';
import QueryError from 'components/atomic-components/QueryError';
import { InstantResultsTemplate } from 'components/search/templates/InstantResultsTemplate';
import { InstantResultsAriaLabelTemplate } from 'components/search/templates/InstantResultsAriaLabelTemplate';
import { useMemo } from 'react';
import { useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import { getLanguageName } from 'lib/navigation/navigation-utils';

const PressRoomArchive = (): JSX.Element => {
  const { data: session } = useSession();
  const [hydrate, setHydrate] = useState(false);
  const navigationState = useAppSelector((state) => state.navigation);
  const { locale } = useI18n();

  const searchEngine = useMemo(
    () =>
      buildSearchEngine({
        configuration: {
          accessToken:
            process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
              ? process.env.COVEO_ACCESS_TOKEN
              : '',
          organizationId:
            process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
              ? process.env.COVEO_ORGANIZATION
              : '',
          search: {
            pipeline: 'PressRoom Archive',
          },
          preprocessRequest: (request, clientOrigin) => {
            const language = locale();
            if (language == null || language.length === 0) {
              return request;
            }

            if (clientOrigin == 'searchApiFetch') {
              const body = JSON.parse(request.body?.toString() ?? '');
              body.aq = `@language==(${getLanguageName(language)}) OR (NOT @language)`;
              request.body = JSON.stringify(body);
            }

            return request;
          },
        },
      }),
    [locale]
  );

  useEffect(() => {
    setHydrate(true);
    buildContext(searchEngine);
  }, [session, searchEngine]);
  return (
    <>
      <br />
      <br />
      <div className="listing-interface"></div>
      {hydrate && searchEngine && (
        <AtomicSearchInterface
          engine={searchEngine}
          fieldsToInclude='["z95xz95xsmallcreateddate","summary","z95xtemplate","searchdescription","searchtitle","searchimageurl","documenttype","videourl","language"]'
          analytics={true}
          reflectStateInUrl={false}
          language={navigationState.currentLanguage}
          languageAssetsPath="/searchlabels"
        >
          <AtomicSearchLayout>
            <AtomicLayoutSection section="search"></AtomicLayoutSection>
            <AtomicLayoutSection section="facets">
              <AtomicFacetManager>
                <AtomicTimeframeFacet label="Timeframe" withDatePicker />
              </AtomicFacetManager>
            </AtomicLayoutSection>
            <AtomicLayoutSection section="main">
              <AtomicSearchBox>
                <AtomicSearchBoxQuerySuggestions />
                <AtomicSearchBoxRecentQueries />
                <AtomicSearchBoxInstantResults
                  template={InstantResultsTemplate}
                  imageSize="small"
                  ariaLabelGenerator={InstantResultsAriaLabelTemplate}
                />
              </AtomicSearchBox>
              <AtomicLayoutSection section="status">
                <AtomicQuerySummary />
                <AtomicSortDropdown>
                  <AtomicSortExpression label="relevance" expression="relevancy" />
                  <AtomicSortExpression label="Date (older first)" expression="date ascending" />
                  <AtomicSortExpression label="Date (newer first)" expression="date descending" />
                </AtomicSortDropdown>
                <AtomicDidYouMean />
              </AtomicLayoutSection>

              <AtomicLayoutSection section="results">
                <AtomicResultList display="list" imageSize="large" template={MyTemplate} />
                <QueryError />
                <NoResults enableCancelLastAction={false}></NoResults>
              </AtomicLayoutSection>
              <AtomicLayoutSection section="pagination">
                <AtomicPager />
              </AtomicLayoutSection>
            </AtomicLayoutSection>
          </AtomicSearchLayout>
        </AtomicSearchInterface>
      )}
      <br />
      <br />
    </>
  );
};

const MyTemplate = (result: Result): JSX.Element => {
  const { clickUri } = result;
  const { searchtitle } = result.raw;
  return (
    <>
      <AtomicResultSectionBadges>
        <AtomicResultDate field="z95xz95xsmallcreateddate" format="MMMM D, YYYY" />
      </AtomicResultSectionBadges>
      <AtomicResultSectionTitle>
        <SearchTitle searchTitle={searchtitle as string} href={clickUri} />
      </AtomicResultSectionTitle>
      <AtomicResultSectionExcerpt>
        <AtomicResultText field="summary" />
      </AtomicResultSectionExcerpt>
    </>
  );
};

export default PressRoomArchive;
