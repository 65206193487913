import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { ProductDetails } from 'components/foundation/commerce/graphql/product-details-query-response';
import BaseSubscriptionPricingCards from 'components/pick-a-plan/shop/BaseSubscriptionPricingCards';
import {
  CurrencyVariationData,
  PricingCardSubscriptionProps,
  ProductSetVariationData,
} from 'components/pick-a-plan/shop/pricing-card-subscription-props';
import getPricingCardSubscriptionQuery from 'components/pick-a-plan/shop/pricing-card-subscription-query';
import { PricingCardSubscriptionResponse } from 'components/pick-a-plan/shop/pricing-card-subscription-response';
import { getProductVariations } from 'lib/commerce/product/product-api';
import { getProductDetailsFromProductId } from 'lib/commerce/product/product-util';
import { getWebstore } from 'lib/commerce/webstore/webstore-api';
import { isNonOkResponse } from 'lib/common/common-http-client';
import { GraphQLClient } from 'lib/common/graphql-client';

const SubscriptionPricingCards = (props: PricingCardSubscriptionProps): JSX.Element => {
  return (
    <div className="mx-auto text-almost-black mt-20">
      <BaseSubscriptionPricingCards {...props} />
    </div>
  );
};

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const language = layoutData.sitecore.context.language;
  const response = await getSubscriptionPricingCardData(rendering.dataSource, language);
  if (response == null) {
    return {};
  }

  const productSetVariationData: Array<ProductSetVariationData> = [];

  for (let i = 0; i < response.datasource.userTypeOptions.targetItems.length; i++) {
    const variationData: Array<CurrencyVariationData> = [];
    const productSetId =
      response.datasource.userTypeOptions.targetItems[i].productSet.targetItems[0].productSetId
        .value;
    const currencies = await getCommerceCurrencies();
    for (let j = 0; j < currencies.length; j++) {
      const variationResponse = await getProductVariations({
        productId: productSetId,
        currencyIsoCode: currencies[j],
      });
      if (isNonOkResponse(variationResponse)) {
        console.log('Failed to get product tier details', JSON.stringify(variationResponse));
      } else {
        const productDetails: Array<ProductDetails> = [];
        for (let k = 0; k < variationResponse.data.variants.length; k++) {
          try {
            const product = variationResponse.data.variants[k];
            const details = await getProductDetailsFromProductId(product.productId, 'en');
            if (details == null || details.length == 0) {
              continue;
            }

            productDetails.push(details[0]);
          } catch {
            continue;
          }
        }
        variationData.push({
          ...variationResponse.data,
          currency: currencies[j],
          productDetails,
        });
      }
    }
    productSetVariationData.push({
      productSetId: productSetId,
      variationData,
    });
  }

  return {
    fields: {
      data: {
        datasource: response.datasource,
      },
    },
    productSetVariationData,
    contentType: rendering?.params?.ContentType ?? null,
    colorClasses: null,
    cartPagePath: null,
    caruselDots: null,
  };
};

const getCommerceCurrencies = async (): Promise<Array<string>> => {
  const webStoreData = await getWebstore();
  if (isNonOkResponse(webStoreData)) {
    return ['USD'];
  }

  return webStoreData.data?.supportedCurrencies ?? ['USD'];
};

export const getSubscriptionPricingCardData = async (
  datasource: string,
  language: string
): Promise<PricingCardSubscriptionResponse> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<PricingCardSubscriptionResponse>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPricingCardSubscriptionQuery,
    {
      datasource: datasource,
      language: language,
    }
  );
};

export default SubscriptionPricingCards;
