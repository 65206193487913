import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type PressReleaseProps = ComponentProps & {
  fields: {
    data: {
      contextItem: {
        contacts: {
          targetItems: [
            {
              field: Field<string>;
            },
          ];
        };
        copyrightText: {
          targetItems: [
            {
              field: Field<string>;
            },
          ];
        };
        searchTitle: Field<string>;
        navigationTitle: Field<string>;
        location: Field<string>;
        summary: Field<string>;
        content: Field<string>;
        contactsHeading: Field<string>;
        copyrightHeading: Field<string>;
        pressReleaseDate: {
          formattedDateValue: string;
        };
      };
    };
  };
};

const PressRelease = (props: PressReleaseProps): JSX.Element => {
  const item = props.fields.data?.contextItem;

  const today = new Date();
  const year = today.getFullYear();
  item.copyrightText;

  const publishDate = new Date(item.pressReleaseDate.formattedDateValue);
  return (
    <div className="w-full max-w-screen-2xl mx-auto py-4 text-base-darker text-left align-top leading-[1.875rem] mb-3 lg:text-2xl lg:leading-9">
      <div className="max-w-screen-xl px-6 py-12 mx-6 xl:mx-auto">
        <Text tag="h1" field={item.searchTitle} className="text-[32px] leading-[37px]" />
        <p className="py-4 font-bold text-base">
          <Text tag="span" field={item.location} />, {publishDate.toLocaleDateString()}
        </p>
        <RichText field={item.summary} className="rte italic text-center py-4 text-base" />
        <RichText
          field={item.content}
          className="rte body text-base-darker text-left richTextWithLinks text-base"
        />

        {item.copyrightText.targetItems.length > 0 && (
          <Text tag="h3" field={item.copyrightHeading} className="pt-6 text-xl" />
        )}

        {item.copyrightText.targetItems.length > 0 &&
          item.copyrightText.targetItems.map((copyright, index) => {
            copyright.field.value = copyright?.field.value.replace('[#year#]', year.toString());
            return (
              <div className="richTextWithLinks py-2 text-base" key={index}>
                <RichText
                  field={copyright?.field}
                  tag="div"
                  className="rte body text-base-darker text-left richTextWithLinks text-base"
                />
              </div>
            );
          })}

        {item.contacts.targetItems.length > 0 && (
          <Text tag="h3" field={item.contactsHeading} className="pt-6 text-xl" />
        )}

        {item.contacts.targetItems.length > 0 &&
          item.contacts.targetItems.map((contact, index) => {
            return (
              <div className="richTextWithLinks lg py-2 text-base" key={index}>
                <RichText
                  field={contact?.field}
                  tag="div"
                  className="rte body text-base-darker text-left richTextWithLinks text-base"
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PressRelease;
