import { ImageField, Text, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { useAppSelector } from 'lib/store/hooks';
import { getLegacyDownloads } from '../../lib/plugins/downloads-service';
import {
  PickPlanPricingCardDataSource,
  PricingCardDataSource,
  PricingCardProps,
  Product,
} from '../../renderings/pricing-card/pricing-card-props';
import { useMemo, useEffect, useState } from 'react';
import { useI18n } from 'next-localization';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { Price } from 'lib/commerce/product/model/product-models';
import DownloadSection from './DownloadSection';
import InlineSpinner from 'components/spinner/InlineSpinner';
import BaseVideoModal from 'components/video/BaseVideoModal';
import VideoPlayerIcon from 'components/video/VideoPlayerIcon';
import { youtubeThumbnailProps } from 'src/renderings/VideoBlock';
import { getYoutubeThumbnail } from 'lib/youtube-thumbnail-fetcher';

const scEmptyImage = 'scEmptyImage';

type BasePricingCardProps = {
  pricingCard: PricingCardProps<PricingCardDataSource | PickPlanPricingCardDataSource>;
  fullCta?: JSX.Element | JSX.Element[] | boolean | null;
  miniCta?: JSX.Element | JSX.Element[] | boolean | null;
  frequencyText?: JSX.Element | JSX.Element[] | boolean | null;
  productPricing?: Price | null;
  productItem: Product | null;
  loading: boolean;
};

export type AvidDownloadsLinkData = {
  Description: string;
  Link: string;
};

const BasePricingCard = (props: BasePricingCardProps): JSX.Element => {
  const { t } = useI18n();

  //set myavidlinks data
  const [myAvidLinkData, setMyAvidLinkData] = useState<Array<AvidDownloadsLinkData>>([]);

  //language

  const [legacyDownloadLinksLoaded, setlegacyDownloadLinksLoaded] = useState(false);
  const navigationState = useAppSelector((state) => state.navigation);

  const video = props.pricingCard?.fields?.data?.datasource?.videos?.targetItems?.[0];
  let videoThumbnail =
    video?.stillImage?.jsonValue?.value?.src != null && video?.stillImage?.jsonValue;

  const isYoutubeVideo = video?.sourceId != null;
  const isBrightcoveVideo = video?.videoReference != null;
  const thumbnailURL = video?.videoReference?.targetItems?.[0]?.thumbnailUrl?.value;
  let videoId: string | undefined;
  let playerId: string | undefined;
  let priceCardImageSrc: ImageField | undefined;

  if (isYoutubeVideo) {
    videoId = video?.sourceId?.value;
  } else if (isBrightcoveVideo) {
    videoId = video?.videoReference?.targetItems?.[0]?.ID?.value;
    playerId = video?.player?.targetItem?.[0]?.ID?.value;

    if (!videoThumbnail && thumbnailURL) {
      videoThumbnail = { value: { src: thumbnailURL } };
    }
  }

  if (!isYoutubeVideo) {
    if (!videoThumbnail && thumbnailURL) {
      videoThumbnail = { value: { src: thumbnailURL } };
    }
  }
  const [youtubeVideoThumbnail, setyoutubeVideoThumbnail] = useState<youtubeThumbnailProps>();
  useEffect(() => {
    if (isYoutubeVideo) {
      if (!videoThumbnail) {
        videoId &&
          getYoutubeThumbnail(videoId).then((data: youtubeThumbnailProps) => {
            setyoutubeVideoThumbnail(data);
          });
      } else {
        const thumbnailURL = videoThumbnail?.value && videoThumbnail.value?.src;
        thumbnailURL && setyoutubeVideoThumbnail({ value: { src: thumbnailURL } });
      }
    }
  }, [isYoutubeVideo, videoId, videoThumbnail]);

  const editorActive = isEditorActive();
  useEffect(() => {
    if (!editorActive) {
      const productCode = props?.productItem?.productCode?.value;
      const language = navigationState.currentLanguage ?? 'en';
      const pluginDownloadLink =
        props?.pricingCard?.fields?.data?.datasource?.pluginDownloadFromMyAvid?.value;
      pluginDownloadLink &&
        productCode &&
        getLegacyDownloads(productCode, language).then((data: AvidDownloadsLinkData[]) => {
          setMyAvidLinkData(data);
          setlegacyDownloadLinksLoaded(true);
        });
    }
  }, [
    editorActive,
    props?.productItem?.productCode?.value,
    props?.pricingCard?.fields?.data?.datasource?.pluginDownloadFromMyAvid?.value,
    navigationState.currentLanguage,
  ]);

  const { pricingCard, fullCta, miniCta, frequencyText, productPricing, productItem, loading } =
    props;

  const hasMyAvidPluginDownloads =
    props?.pricingCard?.fields?.data?.datasource?.pluginDownloadFromMyAvid?.value;

  const hasBadge =
    pricingCard.fields?.data?.datasource?.badge?.jsonValue?.value != null &&
    pricingCard.fields?.data?.datasource?.badge?.jsonValue?.value.length > 0;

  const hasSubTitle =
    pricingCard.fields?.data?.datasource?.subTitle?.jsonValue?.value != null &&
    pricingCard.fields?.data?.datasource?.subTitle?.jsonValue?.value.length > 0;

  const hasFooter =
    pricingCard.fields?.data?.datasource?.footer?.jsonValue?.value != null &&
    pricingCard.fields?.data?.datasource?.footer?.jsonValue?.value.length > 0;

  const priceCardImage = pricingCard.fields?.data?.datasource?.image?.jsonValue.value?.src;
  const priceCardImageIsEmpty =
    pricingCard.fields?.data?.datasource?.image?.jsonValue.value?.class == scEmptyImage ||
    priceCardImage == undefined;
  const productImageIsEmpty =
    productItem?.productImage?.jsonValue?.value?.class == scEmptyImage ||
    JSON.stringify(productItem?.productImage?.jsonValue?.value) == '{}';
  //Try to find image in this order: Pricing Card, Product Item, External Product Image, Default Sitecore Empty Image
  if (priceCardImage != undefined && !priceCardImageIsEmpty) {
    priceCardImageSrc = pricingCard.fields?.data?.datasource?.image?.jsonValue;
  } else if (productItem?.productImage?.jsonValue != undefined && !productImageIsEmpty) {
    priceCardImageSrc = productItem?.productImage?.jsonValue;
  } else if (productItem?.externalProductImageUrl?.value != '') {
    const externalImage: ImageField = {
      value: {
        src: productItem?.externalProductImageUrl?.value,
        alt: productItem?.productName?.value,
      },
    };
    priceCardImageSrc = externalImage;
  } else {
    priceCardImageSrc = pricingCard.fields?.data?.datasource?.image?.jsonValue;
  }

  const onSale = useMemo(() => {
    if (productPricing == null) {
      return false;
    }

    return productPricing.unitPrice != productPricing.listPrice;
  }, [productPricing]);

  const isFree = useMemo(() => {
    if (productPricing == null) {
      return false;
    }

    return productPricing.unitPrice == 0;
  }, [productPricing]);

  if (productItem == null) {
    if (editorActive) {
      return <div className="editor-message">Please select a product on the page item</div>;
    }

    return <></>;
  }

  if (pricingCard.fields?.data?.datasource == null) {
    if (editorActive) {
      return <div className="editor-message">Please select a datasource.</div>;
    }

    return <></>;
  }
  return (
    <>
      {pricingCard.mini && (
        <div className="m-2 flex justify-between items-center w-full md:w-fit pr-3">
          <div className="flex items-center justify-start space-x-2 w-full md:w-fit">
            {onSale && (
              <div className="hidden lg:block line-through text-base-dark text-[0.8125rem] tracking-[0.2em]">
                {t(`Currency_Symbol_${productPricing?.currencyIsoCode}`)}
                {productPricing?.listPrice}
              </div>
            )}

            <div className="hidden lg:block text-[1.25rem] tracking-[0.2em]">
              {isFree && <span className="mb-2">{t('PricingCard_Free')}</span>}
              {!isFree && (
                <>
                  {t(`Currency_Symbol_${productPricing?.currencyIsoCode}`)}
                  {productPricing?.unitPrice}
                </>
              )}
            </div>
            <div className="text-right flex-grow">{miniCta}</div>
          </div>
        </div>
      )}
      {!pricingCard.mini && (
        <div className="relative grid justify-center grid-cols-1 mx-auto md:grid-cols-3 2xl:max-w-4xl shadow-card">
          <div className="rounded-t md:rounded-l md:rounded-tr-none overflow-hidden flex flex-col md:col-span-2 aspect-[4/3] relative bg-base-light">
            {isYoutubeVideo ? (
              <BaseVideoModal
                brightcoveVideo={isBrightcoveVideo}
                youtubeVideo={isYoutubeVideo}
                videoId={videoId}
                playerId={playerId}
                openCtaClassName=""
                openCtaChildren={
                  <div className="before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-full before:h-full">
                    <span className={`${youtubeVideoThumbnail}`}></span>
                    {youtubeVideoThumbnail ? (
                      <NextImage layout="fill" field={youtubeVideoThumbnail} className={`w-full`} />
                    ) : (
                      <span className="w-[175px] h-[175px] bg-base-lighter pointer-events-none object-cover group-hover:opacity-75 mb-12 rounded" />
                    )}
                    <VideoPlayerIcon className="text-[80px] text-white border-[5px] border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
                  </div>
                }
              />
            ) : isBrightcoveVideo ? (
              <BaseVideoModal
                brightcoveVideo={isBrightcoveVideo}
                youtubeVideo={isYoutubeVideo}
                videoId={videoId}
                playerId={playerId}
                openCtaClassName=""
                openCtaChildren={
                  <div className="before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-full before:h-full">
                    {videoThumbnail ? (
                      <>
                        <NextImage layout="fill" field={videoThumbnail} className={`w-full`} />
                      </>
                    ) : (
                      <span className="w-[175px] h-[175px] bg-base-lighter pointer-events-none object-cover group-hover:opacity-75 mb-12 rounded" />
                    )}
                    <VideoPlayerIcon className="text-[80px] text-white border-[5px] border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
                  </div>
                }
              />
            ) : (
              <NextImage layout="fill" field={priceCardImageSrc} className={`w-full`} />
            )}
          </div>
          <div className="text-center p-6 bg-base-lightest rounded-b md:rounded-r md:rounded-l-none md:flex md:flex-col md:justify-center md:px-8">
            <h4 className="font-bold mb-4">{productItem?.productName?.value}</h4>
            {(hasBadge || editorActive) && (
              <div className="mx-auto mb-5 w-fit bg-info-light text-info rounded-xl px-2 py-1 font-bold uppercase text-sm tracking-wide">
                <span>
                  <Text field={pricingCard.fields.data.datasource.badge.jsonValue} />
                </span>
              </div>
            )}

            {productPricing != null && (
              <>
                {onSale && (
                  <div className="line-through text-base-dark tracking-[0.55rem] lg:text-xl">
                    {t(`Currency_Symbol_${productPricing?.currencyIsoCode}`)}
                    {productPricing?.listPrice}
                  </div>
                )}

                <div className="accent-1 tracking-[0.55rem] mb-1 uppercase">
                  {isFree && <div className="mb-2">{t('PricingCard_Free')}</div>}
                  {!isFree && (
                    <>
                      {t(`Currency_Symbol_${productPricing?.currencyIsoCode}`)}
                      {productPricing.unitPrice}
                    </>
                  )}
                </div>
                {}
                {!isFree && (
                  <div className="text-xs mb-4 tracking-[0.16rem] uppercase lg:text-[0.8125rem]">
                    {productPricing.currencyIsoCode}
                    {frequencyText}
                  </div>
                )}
              </>
            )}

            {loading && (
              <div className="mb-2">
                <InlineSpinner />
              </div>
            )}

            {(hasSubTitle || editorActive) && (
              <div className="mb-5">
                <Text tag="span" field={pricingCard.fields.data.datasource.subTitle.jsonValue} />
              </div>
            )}

            <div className="flex flex-col">
              {hasMyAvidPluginDownloads ? (
                legacyDownloadLinksLoaded && myAvidLinkData && myAvidLinkData.length > 0 ? (
                  <DownloadSection MyAvidLinks={true} MyAvidDownloadOptions={myAvidLinkData} />
                ) : (
                  <>{fullCta}</>
                )
              ) : productItem.children?.results?.length > 0 ? (
                <DownloadSection
                  MyAvidLinks={false}
                  DownloadOptions={productItem.children.results}
                />
              ) : (
                productItem.children?.results?.length <= 0 && <>{fullCta}</>
              )}

              <NextLink
                className="mt-3 btn-link hover:bg-transparent"
                field={pricingCard.fields?.data?.datasource?.secondaryCta?.jsonValue}
              />
            </div>

            {(hasFooter || editorActive) && (
              <div className="mt-5 text-sm">
                <Text tag="span" field={pricingCard.fields.data.datasource.footer.jsonValue} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BasePricingCard;
