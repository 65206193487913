import { Field, LinkField, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  createCheckoutSession,
  removeCheckoutSession,
  savePaymentMethod,
  updateCheckout,
} from 'components/checkout/checkout-slice';
import { ComponentProps } from 'lib/component-props';
import { useI18n } from 'next-localization';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useWebStoreCurrencies } from 'lib/commerce/webstore/webstore-hooks';
import { changeCurrency } from 'components/cart/cart-slice';
import PlaceOrderLink from '../../components/checkout/shop/PlaceOrderLink';
import DropdownMenu from 'components/dropdown/shop/DropdownMenu';
import { useSession } from 'next-auth/react';
import { setCheckoutStage } from 'components/checkout/checkout-slice';
import { useCartData } from 'components/cart/cart-hooks';
import Spinner from 'components/spinner/Spinner';
import { getStoredCountry } from 'lib/commerce/countries/countries-provider';
import { AnalyticsProductItem, pushBeginCheckout } from 'lib/google-analytics/commerce';
import { getCountryCurrency } from 'lib/middleware/language-provider';
import dynamic from 'next/dynamic';
import Checkbox from 'components/checkbox/shop/Checkbox';
import { getBearerToken } from 'lib/authentication/account-provider';
import { isFeatureEnabled } from 'lib/feature-flags/feature-flags';
const ExpressPayments = dynamic(() => import('components/cart/shop/ExpressPayments'));
const CouponDetails = dynamic(() => import('components/cart/shop/CouponDetails'));
const CartItem = dynamic(() => import('components/cart/shop/CartItem'));

type SummaryProps = ComponentProps & {
  fields: {
    title: Field<string>;
    eulaUS: Field<string>;
    eulaNonUS: Field<string>;
    link: LinkField;
    orderProcessingLink: LinkField;
  };
};

const CheckoutSummary = (props: SummaryProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const {
    checkoutData,
    checkoutId,
    checkoutDataStatus,
    selectedSource,
    checkoutStage,
    billingAddress,
  } = useAppSelector((state) => state.checkout);

  const currencies = useWebStoreCurrencies();
  const currencyOptions = currencies.map((currency) => {
    return { value: currency, label: `${currency} ${t(`Currency_Symbol_${currency}`)}` };
  });
  const countries = useAppSelector((state) => state.countries.countriesData);

  const customerInfo = useAppSelector((state) => state.customer);
  const customerBillingCountry = billingAddress?.country;
  const customerDataStatus = customerInfo?.customerDataStatus;

  const cartItemData = useCartData();
  const cart = useAppSelector((state) => state.cart);
  const currencyIsoCode = cart?.currencyIsoCode;

  const cartItems = cartItemData?.cartItems;
  const cartSummary = cartItemData?.cartSummary;
  const cartId = cartItemData?.cartSummary.cartId as string;
  const cartItemStatus = cart.cartItemsDataStatus;

  const checkout = useAppSelector((state) => state.checkout);
  const checkoutStatus = checkout.checkoutDataStatus;
  const createOrderStatus = checkout.createOrderStatus;
  const applyCouponStatus = cart?.applyCouponStatus;
  const country = getStoredCountry();

  const showFinalTotals = checkoutDataStatus === 'succeeded' && selectedSource != null;
  const total = showFinalTotals
    ? checkoutData?.digitalRiverResponse?.totalAmount ?? 0
    : cartSummary?.totalProductAmountAfterAdjustments ?? 0;
  const subTotal = showFinalTotals ? checkoutData?.digitalRiverResponse?.subtotal ?? 0 : 0;
  const taxes = showFinalTotals ? checkoutData?.digitalRiverResponse?.totalTax ?? 0 : 0;

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [lastTotal, setLastTotal] = useState(0);

  useEffect(() => {
    const isMultiCurrenciesPaymentMethodsEnabled = isFeatureEnabled(
      'multi-currency-payment-methods-SITECORE-34'
    );

    const updateCurrency = async (): Promise<void> => {
      const countryCurrency = countries.find(
        (countryData) => customerBillingCountry === countryData.countryCode
      )?.currencyISoCode;
      if (countryCurrency && countryCurrency !== currencyIsoCode) {
        await dispatch(
          changeCurrency({ currencyIsoCode: countryCurrency, bearerToken: bearerToken })
        );
        await dispatch(removeCheckoutSession());
      }
    };

    if (isMultiCurrenciesPaymentMethodsEnabled && customerBillingCountry && countries?.length > 0) {
      updateCurrency();
    }

    if (
      !isMultiCurrenciesPaymentMethodsEnabled &&
      customerBillingCountry &&
      customerDataStatus === 'succeeded'
    ) {
      const countryCurrency = getCountryCurrency(customerBillingCountry);
      if (countryCurrency !== '' && countryCurrency !== currencyIsoCode) {
        dispatch(changeCurrency({ currencyIsoCode: countryCurrency, bearerToken: bearerToken }));
      }
    }
  }, [
    customerBillingCountry,
    customerDataStatus,
    dispatch,
    currencyIsoCode,
    bearerToken,
    countries,
  ]);

  useEffect(() => {
    if (bearerToken == '') {
      return;
    }

    setAcceptedTerms(false);
    if (selectedSource == null || cartItemData?.cartSummary?.cartId == null) {
      return;
    }

    if (checkoutId == null) {
      dispatch(createCheckoutSession({ store: 'Webstore', bearerToken: bearerToken }));
      return;
    }

    if (selectedSource.forceSave) {
      dispatch(savePaymentMethod({ sourceId: selectedSource.source.id, bearerToken: bearerToken }));
    }

    const isMultiCurrenciesPaymentMethodsEnabled = isFeatureEnabled(
      'multi-currency-payment-methods-SITECORE-34'
    );

    dispatch(
      updateCheckout({
        checkoutId: checkoutId,
        sourceId: selectedSource.source.id,
        bearerToken: bearerToken,
        cartId: isMultiCurrenciesPaymentMethodsEnabled ? cartId : undefined,
      })
    );
  }, [
    dispatch,
    selectedSource,
    checkout.paymentSessionId,
    checkoutId,
    cartItemData?.cartSummary?.cartId,
    bearerToken,
    cartId,
    t,
  ]);

  useEffect(() => {
    if (checkoutDataStatus === 'succeeded' && selectedSource !== null) {
      dispatch(setCheckoutStage('confirmation'));
    }
  }, [checkoutDataStatus, dispatch, selectedSource]);

  useEffect(() => {
    if (currencyIsoCode == null || cartItemData == null) {
      return;
    }

    const cartTotal = parseFloat(cartItemData.cartSummary.totalProductAmountAfterAdjustments);
    if (lastTotal == cartTotal) {
      return;
    }

    const couponCode = cartItemData?.cartSummary?.coupons?.[0]?.couponCode;

    pushBeginCheckout(
      currencyIsoCode,
      cartTotal.toString(),
      couponCode ?? '',
      cartItemData.cartItems.map((item, index): AnalyticsProductItem => {
        const cartItem = item.cartItem;
        return {
          id: cartItem.productId,
          sku: cartItem.productDetails.sku,
          name: cartItem.name,
          price: cartItem.salesPrice,
          discount: (
            parseFloat(cartItem.listPrice) -
            parseFloat(cartItem.salesPrice) +
            Math.abs(parseFloat(cartItem.totalAdjustmentAmount))
          )
            .toFixed(2)
            .toString(),
          variant: cartItem.productDetails.fields.XC_SubscriptionType__c,
          brand: cartItem.productDetails.fields.VendorName__c,
          index: index.toString(),
          quantity: cartItem.quantity,
          listName: 'Checkout',
          listId: 'Checkout_0',
          itemCategory: cartItem.productCategoryPaths.primary?.at(-1)?.name,
          itemCategory2: cartItem.productCategoryPaths.path1?.at(-1)?.name,
          itemCategory3: cartItem.productCategoryPaths.path2?.at(-1)?.name,
          itemCategory4: cartItem.productCategoryPaths.path3?.at(-1)?.name,
        };
      })
    );
    setLastTotal(cartTotal);
  }, [cartItemData, currencyIsoCode, lastTotal]);

  const onChangeCurrencyHandler = async (currency: string): Promise<void> => {
    if (currency == currencyIsoCode) {
      return;
    }

    dispatch(changeCurrency({ currencyIsoCode: currency, bearerToken: bearerToken }));
  };

  const onChangeCheckboxHandler = (isChecked: boolean): void => {
    setAcceptedTerms(isChecked);
  };

  return (
    <>
      <div className="summary relative w-full max-w-[450px] xl:max-w-none mx-auto border border-base-normal p-6 rounded bg-white overflow-hidden">
        <h3 className="text-almost-black font-bold mb-4 text-2xl 2xl:text-[1.75rem] text-center xl:text-left">
          {customerInfo?.customerData?.firstName != null && (
            <span>{`${t('Checkout_Greeting_User')} ${customerInfo.customerData.firstName}, `}</span>
          )}
          {t('Checkout_Greeting_Base')}
        </h3>
        {currencyIsoCode && (
          <DropdownMenu
            key={currencyIsoCode}
            defaultValue={{
              value: currencyIsoCode,
              label: `${currencyIsoCode} ${t(`Currency_Symbol_${currencyIsoCode}`)}`,
            }}
            options={currencyOptions}
            passValueToParentFunction={onChangeCurrencyHandler}
            className="mb-6"
            isEditable={false}
          />
        )}

        {(checkoutStatus == 'loading' ||
          cartItemStatus == 'loading' ||
          createOrderStatus == 'loading' ||
          applyCouponStatus === 'loading') && <Spinner />}
        {cartItems != null && cartSummary != null && (
          <>
            {cartItems.map((item) => {
              return (
                <CartItem
                  key={item.cartItem.cartItemId}
                  cartItem={item.cartItem}
                  currencyIsoCode={cartSummary.currencyIsoCode}
                  editable={checkoutStage !== 'confirmation'}
                  showPricingAndTerms={showFinalTotals}
                />
              );
            })}
            <div className="wrapper flex flex-col text-almost-black">
              <CouponDetails isEditable={!showFinalTotals} />
              {showFinalTotals && (
                <>
                  {cartSummary.coupons.length <= 0 && (
                    <h3 className="font-normal text-base flex justify-between space-x-10 mb-2">
                      <span>{t('Checkout_SubTotal')}</span>
                      <span>
                        {t(`Currency_Symbol_${currencyIsoCode}`)}
                        {subTotal}
                      </span>
                    </h3>
                  )}
                  <h3 className="font-normal text-base flex justify-between space-x-10 mb-2">
                    <span>{t('Checkout_Taxes')}</span>
                    <span>
                      {t(`Currency_Symbol_${currencyIsoCode}`)}
                      {taxes}
                    </span>
                  </h3>
                </>
              )}

              <h3 className="font-normal text-2xl flex justify-between space-x-10 mb-4">
                <span>{t(`${showFinalTotals ? 'Checkout_OrderTotal' : 'Cart_Total'}`)}</span>
                <span className="font-bold">
                  {t(`Currency_Symbol_${currencyIsoCode}`)}
                  {total}
                </span>
              </h3>
              {showFinalTotals &&
                (country === 'US' ? (
                  <RichText
                    tag="div"
                    field={props.fields.eulaUS}
                    className="accept-terms body rte mt-4 text-base mb-6 [&>*>a]:text-primary hover:[&>*>a]:underline"
                  />
                ) : (
                  <Checkbox
                    checkboxKey={acceptedTerms.toString()}
                    sitecoreLabel={props.fields.eulaNonUS}
                    passValueToParentFunction={onChangeCheckboxHandler}
                    defaultChecked={acceptedTerms}
                    className="accept-terms mt-4 mb-6"
                  />
                ))}

              {country === 'US' ? (
                props.fields.link && (
                  <PlaceOrderLink
                    label={t('Checkout_Proceed_US')}
                    link={props.fields.link}
                    orderProcessingLink={props.fields.orderProcessingLink}
                    acceptedTerms={true}
                  />
                )
              ) : (
                <PlaceOrderLink
                  label={t('Checkout_Proceed_NonUS')}
                  link={props.fields.link}
                  orderProcessingLink={props.fields.orderProcessingLink}
                  acceptedTerms={acceptedTerms}
                />
              )}
            </div>

            {(!showFinalTotals || checkoutStage === 'payment-billing') && <ExpressPayments />}

            <div className="wrapper flex flex-col items-end">
              {!showFinalTotals && (
                <div className="mt-6 text-center text-sm text-neutral-medium">
                  * Does not include any applicable taxes, which are calculated during checkout.
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withDatasourceCheck()<SummaryProps>(CheckoutSummary);
