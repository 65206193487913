import gql from 'graphql-tag';

const getManualCardQuery = gql`
  query ManualCardQuery($datasource: String!, $language: String!) {
    datasource: item(path: $datasource, language: $language) {
      ... on _BrightcoveVideoModal {
        videos {
          targetIds
        }
      }
      ... on _ImageDevOpsFeatures {
        byPassNextJsImageOptimization {
          jsonValue
        }
      }

      ... on CardCarouselManualVideoItem {
        title {
          jsonValue
        }
        categorySubheading {
          jsonValue
        }
        description {
          jsonValue
        }
        link {
          jsonValue
        }
        imageCDNUrl {
          jsonValue
        }
      }

      ... on CardCarouselManualItem {
        title {
          jsonValue
        }
        categorySubheading {
          jsonValue
        }
        description {
          jsonValue
        }
        link {
          jsonValue
        }
        image {
          jsonValue
        }
        imageCDNUrl {
          jsonValue
        }
      }

      ... on CardCarouselManualIconItem {
        title {
          jsonValue
        }
        categorySubheading {
          jsonValue
        }
        description {
          jsonValue
        }
        link {
          jsonValue
        }
        imageCDNUrl {
          jsonValue
        }
        icon: cardCarouselIcon {
          targetItem {
            id
            ... on ReferenceStringValue {
              value {
                jsonValue
              }
            }
          }
        }
      }

      ... on _PrimaryLinkCustomizations {
        noFollow {
          jsonValue
        }
      }
    }
  }
`;

export default getManualCardQuery;
