import { GetCartItemsData } from 'components/cart/cart-models';
import { DigitalRiverPlaceOrderData } from 'components/checkout/checkout-models';
import { CurrencyData } from 'lib/commerce/webstore/model/webstore-models';
import { dataLayerPush } from './data-layer-orchestrator';

export type AnalyticsProductItem = {
  id: string;
  sku: string;
  name: string;
  discount: string;
  brand: string;
  price: string;
  index?: string;
  listId?: string;
  listName?: string;
  quantity?: string;
  variant: string;
  itemCategory?: string;
  itemCategory2?: string;
  itemCategory3?: string;
  itemCategory4?: string;
  productSku?: string;
};

const affiliation = 'Avid Webstore';

export const pushRemoveFromCart = (
  currency: string,
  cartValue: string,
  analyticsItem: AnalyticsProductItem
): void => {
  pushCartEvent('remove_from_cart', currency, cartValue, analyticsItem);
};

export const pushAddToCart = (
  currency: string,
  cartValue: string,
  analyticsItem: AnalyticsProductItem
): void => {
  pushCartEvent('add_to_cart', currency, cartValue, analyticsItem);
};

const pushCartEvent = (
  eventName: string,
  currency: string,
  cartValue: string,
  analyticsItem: AnalyticsProductItem
): void => {
  dataLayerPush({
    dataLayer: {
      event: eventName,
      ecommerce: {
        currency: currency,
        value: cartValue,
        items: [
          {
            item_id: analyticsItem.sku,
            item_name: analyticsItem.name,
            affiliation: affiliation,
            currency: currency,
            discount: analyticsItem.discount,
            index: analyticsItem.index,
            item_brand: analyticsItem.brand,
            ...(analyticsItem.itemCategory
              ? { item_category: analyticsItem.itemCategory }
              : { item_category: 'NA' }),
            ...(analyticsItem.itemCategory2 && { item_category2: analyticsItem.itemCategory2 }),
            ...(analyticsItem.itemCategory3 && { item_category3: analyticsItem.itemCategory3 }),
            ...(analyticsItem.itemCategory4 && { item_category4: analyticsItem.itemCategory4 }),
            item_variant: analyticsItem.variant,
            price: analyticsItem.price,
            quantity: analyticsItem.quantity ?? 1,
            productSku: analyticsItem?.productSku,
          },
        ],
      },
    },
  });
};

export const pushCoveoAddCartItem = async (
  skuToAdd: string,
  name: string,
  price: number,
  currency: string,
  productCategory: string
): Promise<void> => {
  window?.coveoAddProduct?.(skuToAdd, name, price, currency, productCategory);
};
export const pushCoveoRemoveCartItem = async (
  skuToRemove: string,
  name: string,
  price: number,
  currency: string,
  productCategory: string,
  quantity: string
): Promise<void> => {
  window?.coveoRemoveProduct?.(skuToRemove, name, price, currency, productCategory, quantity);
};

export type CoveoAnalyticsOrderItem = {
  product: {
    id: string;
    productName: string;
    price: number;
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    category?: any;
    quantity: number;
  };
};
export const pushCoveoPurchase = async (
  orderItems: CoveoAnalyticsOrderItem[],
  orderId: string,
  affiliation: string,
  revenue: number,
  tax: number,
  shipping: number,
  clientId: string,
  siteContext: string,
  currentCurrency: string
): Promise<void> => {
  window?.coveoSendPurchase?.(
    orderItems,
    orderId,
    affiliation,
    revenue,
    tax,
    shipping,
    clientId,
    siteContext,
    currentCurrency
  );
};
export const pushViewCart = (
  currency: string,
  cartValue: string,
  items: Array<AnalyticsProductItem>
): void => {
  dataLayerPush({
    dataLayer: {
      event: 'view_cart',
      ecommerce: {
        currency: currency,
        value: cartValue,
        items: items.map((analyticsItem) => {
          return {
            item_id: analyticsItem.sku,
            item_name: analyticsItem.name,
            affiliation: affiliation,
            currency: currency,
            discount: analyticsItem.discount,
            index: analyticsItem.index,
            item_brand: analyticsItem.brand,
            ...(analyticsItem.itemCategory
              ? { item_category: analyticsItem.itemCategory }
              : { item_category: 'NA' }),
            ...(analyticsItem.itemCategory2 && { item_category2: analyticsItem.itemCategory2 }),
            ...(analyticsItem.itemCategory3 && { item_category3: analyticsItem.itemCategory3 }),
            ...(analyticsItem.itemCategory4 && { item_category4: analyticsItem.itemCategory4 }),
            item_variant: analyticsItem.variant,
            price: analyticsItem.price,
            quantity: analyticsItem.quantity,
            productSku: analyticsItem?.productSku,
          };
        }),
      },
    },
  });
};

export const pushBeginCheckout = (
  currency: string,
  cartValue: string,
  coupon: string,
  items: Array<AnalyticsProductItem>
): void => {
  dataLayerPush({
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        currency: currency,
        value: cartValue,
        coupon: coupon,
        items: items.map((analyticsItem) => {
          return {
            item_id: analyticsItem.sku,
            item_name: analyticsItem.name,
            affiliation: affiliation,
            coupon: coupon,
            currency: currency,
            discount: analyticsItem.discount,
            index: analyticsItem.index,
            item_brand: analyticsItem.brand,
            ...(analyticsItem.itemCategory
              ? { item_category: analyticsItem.itemCategory }
              : { item_category: 'NA' }),
            ...(analyticsItem.itemCategory2 && { item_category2: analyticsItem.itemCategory2 }),
            ...(analyticsItem.itemCategory3 && { item_category3: analyticsItem.itemCategory3 }),
            ...(analyticsItem.itemCategory4 && { item_category4: analyticsItem.itemCategory4 }),
            item_variant: analyticsItem.variant,
            price: analyticsItem.price,
            quantity: analyticsItem.quantity,
            item_list_name: analyticsItem.listName,
            item_list_id: analyticsItem.listId,
          };
        }),
      },
    },
  });
};

export const pushViewPromotion = (
  creativeName: string,
  creativeSlot: string,
  promotionId: string,
  promotionName: string,
  item?: AnalyticsProductItem
): void => {
  pushPromotionEvent(
    'view_promotion',
    creativeName,
    creativeSlot,
    promotionId,
    promotionName,
    item
  );
};

export const pushSelectPromotion = (
  creativeName: string,
  creativeSlot: string,
  promotionId: string,
  promotionName: string,
  item?: AnalyticsProductItem
): void => {
  pushPromotionEvent(
    'select_promotion',
    creativeName,
    creativeSlot,
    promotionId,
    promotionName,
    item
  );
};

const pushPromotionEvent = (
  eventName: string,
  creativeName: string,
  creativeSlot: string,
  promotionId: string,
  promotionName: string,
  item?: AnalyticsProductItem
): void => {
  dataLayerPush({
    dataLayer: {
      event: eventName,
      ecommerce: {
        creative_name: creativeName,
        creative_slot: creativeSlot,
        promotion_id: promotionId,
        promotion_name: promotionName,
        ...(item && {
          items: [
            {
              item_id: item?.sku,
              item_name: item?.name,
              affiliation: affiliation,
              discount: item?.discount,
              index: item?.index,
              item_brand: item?.brand,
              ...(item?.itemCategory
                ? { item_category: item.itemCategory }
                : { item_category: 'NA' }),
              ...(item?.itemCategory2 && { item_category2: item.itemCategory2 }),
              ...(item?.itemCategory3 && { item_category3: item.itemCategory3 }),
              ...(item?.itemCategory4 && { item_category4: item.itemCategory4 }),
              item_list_id: item?.listId,
              item_list_name: item?.listName,
              item_variant: item?.variant,
              price: item?.price,
              quantity: item?.quantity,
            },
          ],
        }),
      },
    },
  });
};

export const pushPaymentEvent = (
  eventName: string,
  paymentType: string,
  currency: string,
  cartValue: string,
  items: Array<AnalyticsProductItem>,
  coupon?: string
): void => {
  //TagManager.dataLayer = TagManager.dataLayer || [];
  dataLayerPush({
    dataLayer: {
      event: eventName,
      ecommerce: {
        currency,
        value: cartValue,
        coupon,
        payment_type: paymentType,
        items: items.map((item) => {
          return {
            item_id: item.sku,
            item_name: item.name,
            affiliation: affiliation,
            discount: item.discount,
            index: item.index,
            item_brand: item.brand,
            ...(item.itemCategory ? { item_category: item.itemCategory } : { item_category: 'NA' }),
            ...(item.itemCategory2 && { item_category2: item.itemCategory2 }),
            ...(item.itemCategory3 && { item_category3: item.itemCategory3 }),
            ...(item.itemCategory4 && { item_category4: item.itemCategory4 }),
            item_variant: item.variant,
            price: item?.price,
            quantity: item?.quantity,
          };
        }),
      },
    },
  });
};

export const pushAddBillingInfo = (
  currency: string,
  cartValue: string,
  items: Array<AnalyticsProductItem>,
  coupon?: string
): void => {
  //TagManager.dataLayer = TagManager.dataLayer || [];
  dataLayerPush({
    dataLayer: {
      event: 'add_billing_info',
      ecommerce: {
        currency,
        value: cartValue,
        coupon,
        items: items.map((item) => {
          return {
            item_id: item.sku,
            item_name: item.name,
            affiliation: affiliation,
            discount: item.discount,
            index: item.index,
            item_brand: item.brand,
            ...(item.itemCategory ? { item_category: item.itemCategory } : { item_category: 'NA' }),
            ...(item.itemCategory2 && { item_category2: item.itemCategory2 }),
            ...(item.itemCategory3 && { item_category3: item.itemCategory3 }),
            ...(item.itemCategory4 && { item_category4: item.itemCategory4 }),
            item_variant: item.variant,
            price: item?.price,
            quantity: item?.quantity,
          };
        }),
      },
    },
  });
};

export const currencyConvertor = (cartValue: number, conversionData: CurrencyData): string => {
  return (cartValue / conversionData.conversionRate).toFixed(2);
};

export const pushAddPurchaseEvent = (
  transactionData: DigitalRiverPlaceOrderData,
  currency: string,
  currencyConversionData: CurrencyData,
  cartValue: string,
  items: Array<AnalyticsProductItem>,
  coupon?: string,
  userId?: string
): void => {
  //TagManager.dataLayer = TagManager.dataLayer || [];
  dataLayerPush({
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        transaction_id: transactionData.id,
        affiliation: affiliation,
        tax: transactionData.totalTax,
        shipping: transactionData.totalShipping,
        currency,
        value: cartValue,
        coupon,
        usd_transaction_value: currencyConvertor(parseFloat(cartValue), currencyConversionData),
        user_id: userId,
        items: items.map((item) => {
          return {
            item_id: item.sku,
            item_name: item.name,
            affiliation: affiliation,
            discount: item.discount,
            index: item.index,
            item_brand: item.brand,
            ...(item.itemCategory ? { item_category: item.itemCategory } : { item_category: 'NA' }),
            ...(item.itemCategory2 && { item_category2: item.itemCategory2 }),
            ...(item.itemCategory3 && { item_category3: item.itemCategory3 }),
            ...(item.itemCategory4 && { item_category4: item.itemCategory4 }),
            item_variant: item.variant,
            price: item?.price,
            quantity: item?.quantity,
          };
        }),
      },
    },
  });
};

export const pushViewItem = (
  currency: string,
  cartValue: string,
  item: AnalyticsProductItem
): void => {
  pushViewItems(currency, cartValue, [item]);
};

export const pushViewItems = (
  currency: string,
  cartValue: string,
  items: Array<AnalyticsProductItem>
): void => {
  dataLayerPush({
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        currency: currency,
        value: cartValue,
        items: items.map((item) => {
          return {
            item_id: item.sku,
            item_name: item.name,
            affiliation: affiliation,
            discount: item.discount,
            index: item.index,
            item_brand: item.brand,
            ...(item.itemCategory ? { item_category: item.itemCategory } : { item_category: 'NA' }),
            ...(item.itemCategory2 && { item_category2: item.itemCategory2 }),
            ...(item.itemCategory3 && { item_category3: item.itemCategory3 }),
            ...(item.itemCategory4 && { item_category4: item.itemCategory4 }),
            item_list_id: item.listId,
            item_list_name: item.listName,
            item_variant: item.variant,
            price: item?.price,
            quantity: item.quantity,
            productSku: item?.productSku,
          };
        }),
      },
    },
  });
};

export const pushViewItemList = (
  itemListId: string,
  itemListName: string,
  currency: string,
  items: Array<AnalyticsProductItem>
): void => {
  dataLayerPush({
    dataLayer: {
      event: 'view_item_list',
      ecommerce: {
        item_list_id: itemListId,
        item_list_name: itemListName,
        currency: currency,
        items: items.map((item) => {
          return {
            item_id: item.sku,
            item_name: item.name,
            affiliation: affiliation,
            discount: item.discount,
            index: item.index,
            item_brand: item.brand,
            ...(item.itemCategory ? { item_category: item.itemCategory } : { item_category: 'NA' }),
            ...(item.itemCategory2 && { item_category2: item.itemCategory2 }),
            ...(item.itemCategory3 && { item_category3: item.itemCategory3 }),
            ...(item.itemCategory4 && { item_category4: item.itemCategory4 }),
            item_list_id: item.listId,
            item_list_name: item.listName,
            item_variant: item.variant,
            price: item?.price,
            quantity: item.quantity,
          };
        }),
      },
    },
  });
};

export const pushSelectItem = (
  itemListId: string,
  itemListName: string,
  currency: string,
  items: Array<AnalyticsProductItem>
): void => {
  dataLayerPush({
    dataLayer: {
      event: 'select_item',
      ecommerce: {
        item_list_id: itemListId,
        item_list_name: itemListName,
        currency: currency,
        items: items.map((item) => {
          return {
            item_id: item.sku,
            item_name: item.name,
            affiliation: affiliation,
            discount: item.discount,
            index: item.index,
            item_brand: item.brand,
            ...(item.itemCategory ? { item_category: item.itemCategory } : { item_category: 'NA' }),
            ...(item.itemCategory2 && { item_category2: item.itemCategory2 }),
            ...(item.itemCategory3 && { item_category3: item.itemCategory3 }),
            ...(item.itemCategory4 && { item_category4: item.itemCategory4 }),
            item_list_id: item.listId,
            item_list_name: item.listName,
            item_variant: item.variant,
            price: item?.price,
            quantity: item.quantity,
          };
        }),
      },
    },
  });
};

export const getProductCartIndex = (
  productId: string,
  cartData: GetCartItemsData | null
): number => {
  let index = 0;
  if (cartData != null) {
    const foundIndex = cartData.cartItems.findIndex((item) => item.cartItem.productId == productId);
    if (foundIndex >= 0) {
      index = foundIndex;
    } else {
      index = cartData.cartItems.length ? cartData.cartItems.length : 0;
    }
  }

  return index >= 0 ? index : 0;
};

export const pushCoveoViewProduct = async (
  skuToAdd: string,
  name: string,
  price: number,
  currency: string,
  productCategory: string
): Promise<void> => {
  window?.coveoViewProduct?.(skuToAdd, name, price, currency, productCategory);
};

export const pushProductClick = (currency: string, item: AnalyticsProductItem): void => {
  dataLayerPush({
    dataLayer: {
      event: 'select_item',
      ecommerce: {
        currency: currency,
        items: [
          {
            item_id: item.sku,
            item_name: item.name,
            affiliation: affiliation,
            discount: item.discount,
            index: item.index,
            item_brand: item.brand,
            ...(item.itemCategory ? { item_category: item.itemCategory } : { item_category: 'NA' }),
            ...(item.itemCategory2 && { item_category2: item.itemCategory2 }),
            ...(item.itemCategory3 && { item_category3: item.itemCategory3 }),
            ...(item.itemCategory4 && { item_category4: item.itemCategory4 }),
            item_variant: item.variant,
            price: item?.price,
            quantity: item.quantity,
          },
        ],
      },
    },
  });
};
