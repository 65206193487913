import {
  Text,
  Field,
  withDatasourceCheck,
  LinkField,
  RichTextField,
  RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { useI18n } from 'next-localization';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseCommonModal from '../components/general-modal/BaseCommonModal';

type TextBlockProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    subheading: Field<string>;
    text: Field<string>;
    showBadge: Field<boolean>;
    badgeTheme: Field<string>;
    ctaButton: LinkField;
    noFollow: Field<boolean>;
    modalHeading: Field<string>;
    modalDescription: RichTextField;
    ctaLabel: Field<string>;
    modalCTA: LinkField;
    containsModal: Field<boolean>;
  };
};

const TextBlock = (props: TextBlockProps): JSX.Element => {
  const badgeCss = props.fields?.showBadge
    ? props.fields?.badgeTheme && props.fields?.badgeTheme?.value === 'dark'
      ? 'text-sm text-left font-bold text-dark px-2 py-0.5 bg-info leading-[16.41px] rounded-[10px] ml-4 dark:bg-info-dark dark:text-info-light'
      : 'text-sm text-left font-bold text-white px-2 py-0.5 bg-info leading-[16.41px] rounded-[10px] ml-4 dark:bg-info-light dark:text-info'
    : '';

  const { t } = useI18n();
  const modalContent = (
    <div role="modal">
      <Text tag="h3" field={props.fields.modalHeading} className="h3 mb-6" />
      <RichText tag="div" field={props.fields.modalDescription} className="rte body mb-6" />
      <Link
        field={props.fields.modalCTA}
        linkCustomizations={props.fields}
        className="btn-link-underline"
        hideText
      >
        <span>{props.fields?.ctaButton?.value?.text}</span>
        <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
      </Link>
    </div>
  );
  return (
    <>
      <Text
        tag="h5"
        field={props.fields.subheading}
        className="font-medium text-base text-secondary text-left align-top leading-[1.2rem] uppercase tracking-[5.04px] mb-2 lg:text-lg lg:leading-[1.35rem]"
      />
      <div className="flex items-center mb-10">
        <Text
          tag="h3"
          field={props.fields.heading}
          className="font-bold text-2xl text-almost-black text-left align-top leading-[1.8rem] lg:text-[1.75rem] lg:leading-[2.1rem]"
        />
        {badgeCss && props.fields?.showBadge?.value == true && (
          <div className={badgeCss}>{t('TextBlock_New')}</div>
        )}
      </div>
      <RichText
        tag="div"
        field={props.fields.text}
        className="rte [&>ul]:mb-0 font-normal text-xl text-almost-black text-left align-top leading-[1.875rem] mb-6 lg:text-2xl lg:leading-9"
      />
      {props.fields?.containsModal?.value ? (
        <BaseCommonModal
          modalContent={modalContent}
          openCtaChildren={
            <>
              <span className="btn-link-underline">
                <span>{props.fields?.ctaLabel?.value}</span>
                <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
              </span>
            </>
          }
        ></BaseCommonModal>
      ) : (
        <Link
          field={props.fields.ctaButton}
          linkCustomizations={props.fields}
          className="btn-link-underline"
          hideText
        >
          <span>{props.fields?.ctaButton?.value?.text}</span>
          <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
        </Link>
      )}
    </>
  );
};

export default withDatasourceCheck()<TextBlockProps>(TextBlock);
