import Spinner from 'components/spinner/Spinner';
import { addCartItem } from 'components/cart/cart-slice';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import { useRouter } from 'next/router';
import { TargetItemFeaturesValue } from 'src/pages/api/product/models/product';
import SubscriptionPricingCardFeatures from './SubscriptionPricingCardFeatures';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { getProductCartIndex, pushAddToCart } from 'lib/google-analytics/commerce';
import { ColorClassesType } from 'src/components/foundation/non-sitecore/themes/themes-options';
import { ProductCategoryPaths } from 'components/cart/cart-models';
import { useSession } from 'next-auth/react';
import { useMemo } from 'react';
import { getBearerToken } from 'lib/authentication/account-provider';

type SubscriptionPricingCardProps = {
  productId: string;
  sku: string;
  productName: string;
  productNameFromSalesforce: string;
  brand: string;
  quantity: number;
  listPrice: number;
  unitPrice: number;
  currencyCode: string;
  subscriptionType: string;
  featuredTheme: string;
  featuredText: Field<string>;
  badgeText: Field<string>;
  featuresHeading: Field<string>;
  features: TargetItemFeaturesValue[];
  className?: string;
  colorClasses: ColorClassesType;
  cartPagePath: string;
  productCategoryPaths?: ProductCategoryPaths;
};

const SubscriptionPricingCard = (props: SubscriptionPricingCardProps): JSX.Element => {
  const { t } = useI18n();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart);
  const cartAddItemStatus = useAppSelector((state) => state.cart.cartAddItemStatus);
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const {
    productId,
    productNameFromSalesforce,
    brand,
    quantity,
    listPrice,
    unitPrice,
    currencyCode,
    subscriptionType,
    featuredTheme,
    featuredText,
    badgeText,
    features,
    featuresHeading,
    className,
    colorClasses,
    productCategoryPaths,
  } = props;

  const getAnnualCost = (): string => {
    switch (props.subscriptionType.toLowerCase()) {
      case 'apu':
        return '';
      case 'apm':
      case 'mpm':
        return `${t(`Currency_Symbol_${currencyCode}`)}${
          Math.round(listPrice * 12 * 100 + Number.EPSILON) / 100
        }`;
      case '2 year':
        return `${t(`Currency_Symbol_${currencyCode}`)}${
          Math.round((listPrice / 2) * 100 + Number.EPSILON) / 100
        }`;
      case '3 year':
        return `${t(`Currency_Symbol_${currencyCode}`)}${
          Math.round((listPrice / 3) * 100 + Number.EPSILON) / 100
        }`;
      default:
        return '';
    }
  };

  const onClickAddToCartHandler = (productId: string): void => {
    if (cart.cartSummaryData == null || quantity < 1) {
      return;
    }

    const index = getProductCartIndex(productId, cart.cartItemsData);
    pushAddToCart(
      currencyCode,
      (
        parseFloat(cart.cartSummaryData.totalProductAmountAfterAdjustments) +
        unitPrice * quantity
      ).toString(),
      {
        id: productId,
        sku: productId,
        name: productNameFromSalesforce,
        brand: brand,
        price: unitPrice.toString(),
        discount: (listPrice - unitPrice).toString(),
        variant: props.sku,
        quantity: quantity.toString(),
        index: index.toString(),
        itemCategory: productCategoryPaths?.primary?.at(-1)?.name,
        itemCategory2: productCategoryPaths?.path1?.at(-1)?.name,
        itemCategory3: productCategoryPaths?.path2?.at(-1)?.name,
        itemCategory4: productCategoryPaths?.path3?.at(-1)?.name,
        productSku: props.sku,
      }
    );

    dispatch(addCartItem({ sku: productId, quantity, bearerToken: bearerToken }))
      .unwrap()
      .then(() => {
        router.push(props.cartPagePath);
      });
  };

  const formatPrice = (num: number): string => (num % 1 === 0 ? num.toString() : num.toFixed(2));

  return (
    <div
      className={`${className} pt-auto text-almost-black flex flex-col items-center basis-0 flex-grow relative`}
    >
      {cartAddItemStatus === 'loading' && (
        <Spinner width={5} height={35} color={colorClasses.subscriptionPricingCard.spinnerColor} />
      )}
      <span
        className={`-z-10 py-[6px] w-full text-center text-white uppercase accent-3 tracking-[0.2em] min-h-[31px] ${
          !featuredText?.value && 'invisible'
        } ${featuredTheme == 'primary' && colorClasses.subscriptionPricingCard.bgFeaturedPrimary} ${
          featuredTheme == 'secondary' && colorClasses.subscriptionPricingCard.bgFeaturedSecondary
        }`}
      >
        {featuredText?.value ? <Text tag="span" field={featuredText} /> : 'n/a'}
      </span>
      <h4 className="product-name pt-[50px] mb-5 font-bold text-center">
        {productNameFromSalesforce}
      </h4>
      <p
        className={`text-info text-sm ${
          colorClasses.general.bgInfo
        } px-2 rounded-[10px] font-bold uppercase mb-5 min-h-[20px] ${
          !badgeText?.value && 'invisible'
        }`}
      >
        {badgeText?.value ? <Text tag="span" field={badgeText} /> : 'n/a'}
      </p>
      <h5
        className={`text-base-dark line-through text-[1.25rem] font-normal tracking-[0.2em] ${
          unitPrice === listPrice && 'invisible'
        }`}
      >
        {t(`Currency_Symbol_${currencyCode}`)}
        {formatPrice(unitPrice)}
      </h5>
      {unitPrice <= 0 && (
        <>
          <h5 className="product-price accent-1 tracking-[0.1em]">{t('PricingCard_Free')}</h5>
          <h5 className="accent-3 tracking-[0.2em] uppercase mb-4">&nbsp;</h5>
          <h5 className="text-sm mb-5 font-normal invisible">&nbsp;</h5>
        </>
      )}
      {unitPrice > 0 && (
        <>
          <h5
            className={`product-price accent-1 tracking-[0.2em] ${
              featuredText?.value &&
              featuredTheme === 'primary' &&
              colorClasses.subscriptionPricingCard.textFeaturedPrimary
            }`}
          >
            {t(`Currency_Symbol_${currencyCode}`)}
            {formatPrice(listPrice)}
          </h5>
          <h5 className="accent-3 tracking-[0.2em] uppercase mb-4">
            {currencyCode}
            {(subscriptionType.toLowerCase() == 'apm' || subscriptionType.toLowerCase() == 'mpm') &&
              t('SubscriptionPricingCard_FrequencyMonthly')}
            {subscriptionType.toLowerCase() == 'apu' &&
              t('SubscriptionPricingCard_FrequencyYearly')}
          </h5>
          <h5 className={`text-sm mb-5 font-normal ${getAnnualCost() === '' && 'invisible'}`}>
            {getAnnualCost() !== ''
              ? `${getAnnualCost()}  ${t('SubscriptionPricingCard_AnnualCost')}`
              : 'null'}
          </h5>
        </>
      )}

      <div className="relative">
        {cartAddItemStatus == 'loading' && (
          <Spinner
            width={4}
            margin={3}
            height={20}
            color={colorClasses.subscriptionPricingCard.spinnerColor}
          />
        )}
        <button
          onClick={(): void => onClickAddToCartHandler(productId)}
          data-id={productId}
          className={`subscription-picing-card-buy-now-btn ${
            featuredText?.value && featuredTheme === 'primary'
              ? colorClasses.subscriptionPricingCard.btnFeaturedPrimary
              : colorClasses.subscriptionPricingCard.btn
          } mb-5`}
        >
          {t('SubscriptionPricingCard_BuyButtonLabel')}
        </button>
      </div>
      {features && features.length > 0 && (
        <SubscriptionPricingCardFeatures
          features={features}
          featureHeading={featuresHeading}
          isFeatured={featuredText?.value.length > 0 && featuredTheme === 'primary'}
          colorClasses={colorClasses}
        />
      )}
    </div>
  );
};

export default SubscriptionPricingCard;
