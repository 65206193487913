import { addCartItem } from 'components/cart/cart-slice';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import { TargetItemFeaturesValue } from 'src/pages/api/product/models/product';
import MPSubscriptionPricingCardFeatures from './MPSubscriptionPricingCardFeatures';
import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { getProductCartIndex, pushAddToCart } from 'lib/google-analytics/commerce';
import { ProductCategoryPaths } from 'components/cart/cart-models';
import { useMemo, useState } from 'react';
import { useCartData } from 'components/cart/cart-hooks';
import InlineSpinner from 'components/spinner/InlineSpinner';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

type MarkeplaceSubscriptionPricingCardProps = {
  productId: string;
  sku: string;
  sfProductName: string;
  productName: string;
  brand: string;
  quantity: number;
  listPrice: number;
  unitPrice: number;
  currencyCode: string;
  subscriptionType: string;
  featuredTheme: string;
  featuredText: Field<string>;
  badgeText: Field<string>;
  features: TargetItemFeaturesValue[];
  className?: string;
  cartPagePath: string;
  productCategoryPaths?: ProductCategoryPaths;
  marketplaceVariantDescription: Field<string>;
};

const MarkeplaceSubscriptionPricingCard = (
  props: MarkeplaceSubscriptionPricingCardProps
): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart);
  const [addToCartSpinner, setAddToCartSpinner] = useState(false);

  const {
    productId,
    productName,
    sfProductName,
    brand,
    quantity,
    listPrice,
    unitPrice,
    currencyCode,
    subscriptionType,
    featuredTheme,
    featuredText,
    badgeText,
    features,
    className,
    productCategoryPaths,
    marketplaceVariantDescription,
  } = props;

  const cartItemData = useCartData();
  const cartItemsIds = cartItemData?.cartItems.map((item): string => item.cartItem?.productId);
  const isInCart = cartItemsIds?.includes(productId) ?? false;
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const getSubscriptionTypeText = (): string => {
    switch (subscriptionType.toLowerCase()) {
      case 'apu':
        return t('SubscriptionPricingCard_AnnualPaidUpfront');
      case 'apm':
        return t('SubscriptionPricingCard_AnnualPaidMonthly');
      case 'mpm':
        return t('SubscriptionPricingCard_MonthToMonth');
      case '2yr':
        return t('SubscriptionPricingCard_2Year');
      case '3yr':
        return t('SubscriptionPricingCard_3Year');
      default:
        return '';
    }
  };

  const getAnnualCost = (): string => {
    switch (props.subscriptionType.toLowerCase()) {
      case 'apu':
        return '';
      case 'apm':
      case 'mpm':
        return `${t(`Currency_Symbol_${currencyCode}`)}${
          Math.round(listPrice * 12 * 100 + Number.EPSILON) / 100
        }`;
      case '2 year':
        return `${t(`Currency_Symbol_${currencyCode}`)}${
          Math.round((listPrice / 2) * 100 + Number.EPSILON) / 100
        }`;
      case '3 year':
        return `${t(`Currency_Symbol_${currencyCode}`)}${
          Math.round((listPrice / 3) * 100 + Number.EPSILON) / 100
        }`;
      default:
        return '';
    }
  };

  const onClickAddToCartHandler = (productId: string): void => {
    if (cart.cartSummaryData == null) {
      return;
    }
    setAddToCartSpinner(true);
    const index = getProductCartIndex(productId, cart.cartItemsData);
    pushAddToCart(
      currencyCode,
      (
        parseFloat(cart.cartSummaryData.totalProductAmountAfterAdjustments) +
        unitPrice * quantity
      ).toString(),
      {
        id: productId,
        sku: props.sku,
        name: productName,
        brand: brand,
        price: unitPrice.toString(),
        discount: (listPrice - unitPrice).toString(),
        variant: productId,
        quantity: quantity.toString(),
        index: index.toString(),
        itemCategory: productCategoryPaths?.primary?.at(-1)?.name,
        itemCategory2: productCategoryPaths?.path1?.at(-1)?.name,
        itemCategory3: productCategoryPaths?.path2?.at(-1)?.name,
        itemCategory4: productCategoryPaths?.path3?.at(-1)?.name,
      }
    );

    dispatch(addCartItem({ sku: productId, quantity, bearerToken: bearerToken }))
      .unwrap()
      .then(() => {
        setAddToCartSpinner(false);
      });
  };

  const getFeaturedClassNames = (): string => {
    let clNames = '';
    if (!featuredText?.value) {
      clNames += 'invisible ';
    }
    if (featuredTheme == 'primary') {
      clNames += 'bg-mp-background-primary ';
    }
    if (featuredTheme == 'secondary') {
      clNames += 'bg-mp-background-secondary ';
    }
    console.log('clNames: ', clNames);
    return clNames;
  };

  const formatPrice = (num: number): string => (num % 1 === 0 ? num.toString() : num.toFixed(2));

  const renderPricesBlock = (): JSX.Element => {
    return (
      <>
        <h5
          className={`text-base-dark line-through text-[1.25rem] font-normal tracking-[0.2em] ${
            unitPrice === listPrice ? 'invisible' : ''
          }`}
        >
          {t(`Currency_Symbol_${currencyCode}`)}
          {formatPrice(listPrice)}
        </h5>
        <h5
          className={`accent-1 tracking-[0.2em] ${
            featuredText?.value && featuredTheme === 'primary' ? 'text-mp-txt-primary' : ''
          }`}
        >
          {unitPrice > 0 ? t(`Currency_Symbol_${currencyCode}`) : ''}
          {unitPrice > 0 ? formatPrice(unitPrice) : t('MP_Free_Price')}
        </h5>
        <h5 className="accent-3 tracking-[0.2em] uppercase mb-2">
          {currencyCode}
          {(subscriptionType.toLowerCase() == 'apm' || subscriptionType.toLowerCase() == 'mpm') &&
            t('SubscriptionPricingCard_FrequencyMonthly')}
        </h5>
        <h5 className={`text-sm mb-3 font-normal ${getAnnualCost() === '' && 'invisible'}`}>
          {getAnnualCost() !== ''
            ? `${getAnnualCost()}  ${t('SubscriptionPricingCard_AnnualCost')}`
            : 'null'}
        </h5>
      </>
    );
  };

  return (
    <div
      className={`${className} pt-auto text-almost-black flex flex-col items-center basis-0 flex-grow relative`}
    >
      <div className="h-1/3 text-center w-full">
        <div
          className={`py-[6px] w-full text-center text-white uppercase accent-3 tracking-[0.2em] min-h-[31px] ${getFeaturedClassNames()}`}
        >
          {featuredText?.value ? <Text tag="span" field={featuredText} /> : 'n/a'}
        </div>
        <h4 className="mb-2 pt-2 text-mp-txt-bright px-3.5">{sfProductName}</h4>
        <h5 className="mb-2 font-bold">{getSubscriptionTypeText()}</h5>
        <p
          className={`text-info text-sm bg-mp-background-neutralLight px-2 rounded-[10px] font-bold uppercase mb-5 min-h-[20px] ${
            !badgeText?.value ? 'invisible' : ''
          }`}
        >
          {badgeText?.value ? <Text tag="span" field={badgeText} /> : 'n/a'}
        </p>
      </div>
      <div className="h-1/3 min-h-[180px] w-full text-center mb-3 mt-1">
        {renderPricesBlock()}
        <div className="relative h-[45px]">
          <button
            onClick={(): void => onClickAddToCartHandler(productId)}
            className={`
              bg-mp-btn-primary hover:bg-mp-btn-light text-lg text-mp-txt-bright font-bold px-4 py-2 rounded absolute bottom-1 left-[calc(50%-50px)] 
              disabled:bg-mp-btn-disabled disabled:pointer-events-none ${
                addToCartSpinner ? 'pb-0' : ''
              }
            `}
            disabled={isInCart}
          >
            {addToCartSpinner ? (
              <InlineSpinner height={20} width={6} color={'#EFF0F4'} />
            ) : (
              `${isInCart ? t('MP_In_Cart') : t('MP_Buy_Now')}`
            )}
          </button>
        </div>
      </div>
      {marketplaceVariantDescription?.value.length > 0 && (
        <div
          className={`h-1/3 w-full space-y-2 flex flex-col bg-mp-background-card px-4 py-3 mt-2 text-mp-txt-neutralLight flex-grow`}
        >
          <h5 className="text-center small-navigation-bold">
            {t('SubscriptionPricingCard_KeyFeaturesTitle')}
          </h5>
          <RichText
            field={marketplaceVariantDescription}
            className="rte mp-rich-text-with-link text-xs"
            tag="div"
          />
        </div>
      )}
      {marketplaceVariantDescription?.value.length <= 0 && features && features.length > 0 && (
        <>
          <MPSubscriptionPricingCardFeatures
            features={features}
            isFeatured={featuredText?.value.length > 0 && featuredTheme === 'primary'}
          />
        </>
      )}
    </div>
  );
};

export default MarkeplaceSubscriptionPricingCard;
