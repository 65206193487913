import { ComponentProps } from 'lib/component-props';
import { useAppSelector } from 'lib/store/hooks';
import Spinner from 'components/spinner/Spinner';
import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComparisonTableCardItem } from '../components/comparison-table/comparison-table-card-item';
import { DropdownItem } from '../components/inline-heading-and-dropdown/dropdown';
import InlineHeadingAndDropdown from '../components/inline-heading-and-dropdown/InlineHeadingAndDropdown';
import { useEffect, useState } from 'react';
import ComparisonTableMobile from '../components/comparison-table/ComparisonTableMobile';
import ComparisonTableDesktop from '../components/comparison-table/ComparisonTableDesktop';
import { useI18n } from 'next-localization';
import { pushViewItems } from 'lib/google-analytics/commerce';
import { useCartData } from 'components/cart/cart-hooks';
import { Variant } from 'lib/commerce/product/model/product-models';

type ComparisonTableProps = ComponentProps & {
  fields: {
    productTableId: Field<string>;
    cards: Array<ComparisonTableCardItem>;
    frequencyTypeOptions: Array<DropdownItem>;
    defaultFrequencyType: Array<DropdownItem>;
    userTypeOptions: Array<DropdownItem>;
    defaultUserType: Array<DropdownItem>;
  };
};

const ComparisonTable = (props: ComparisonTableProps): JSX.Element => {
  const { t } = useI18n();
  const productTierDetails = useAppSelector((state) => state.product.productTierDetailsData);
  const productTierDetailsStatus = useAppSelector((state) => state.product.productTierDetailStatus);
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const cart = useCartData();
  const [productIdsToCards, setProductIdsToCards] = useState<Map<
    string,
    ComparisonTableCardItem
  > | null>(null);

  useEffect(() => {
    if (productTierDetails?.variants == null || props.fields == null) {
      return;
    }

    const fetchSitecoreProductIds = async (): Promise<void> => {
      const productIdToCard = new Map<string, ComparisonTableCardItem>();
      const visibleVariants: Variant[] = [];
      for (let i = 0; i < productTierDetails.variants.length; i++) {
        const productId = productTierDetails.variants[i].productId;
        const card = props.fields.cards.filter(
          (x) => x.fields.product[0].fields.productId.value == productId
        );

        if (card == null || card.length == 0) {
          continue;
        }

        productIdToCard.set(productTierDetails.variants[i].productId, card[0]);
        visibleVariants.push(productTierDetails.variants[i]);
      }
      setProductIdsToCards(productIdToCard);
      if (currencyIsoCode == null || cart == null) {
        return;
      }

      pushViewItems(
        currencyIsoCode,
        cart.cartSummary.totalProductAmountAfterAdjustments,
        visibleVariants.map((variant, index) => {
          return {
            id: variant.productId,
            sku: variant.sku,
            name: variant.name,
            price: variant.price.unitPrice.toString(),
            discount: (variant.price.listPrice - variant.price.unitPrice).toString(),
            brand: variant.brand,
            variant: variant.productId,
            index: index.toString(),
            listName: 'Comparison Table',
            listId: `ComparisonTable_${props.rendering.uid}`,
            itemCategory: variant.productCategoryPaths.primary?.at(-1)?.name,
            itemCategory2: variant.productCategoryPaths.path1?.at(-1)?.name,
            itemCategory3: variant.productCategoryPaths.path2?.at(-1)?.name,
            itemCategory4: variant.productCategoryPaths.path3?.at(-1)?.name,
          };
        })
      );
    };

    fetchSitecoreProductIds();
  }, [cart, currencyIsoCode, productTierDetails, props.fields, props.rendering.uid]);

  return (
    <div id="comparison-table" className="mx-auto text-almost-black mt-20 max-w-content">
      <div className="mb-10 px-6 2xl:px-24 relative">
        <InlineHeadingAndDropdown
          {...props}
          headerText={t('ComparisonTable_DetailedComparison')}
          orientation="vertical"
        />
        {productTierDetailsStatus != 'succeeded' && <Spinner />}
        {productTierDetails !== null && productIdsToCards !== null && (
          <>
            <div className="hidden md:block">
              <ComparisonTableDesktop
                {...props}
                productTierDetails={productTierDetails}
                productIdsToCards={productIdsToCards}
              />
            </div>
            <div className="block md:hidden">
              <ComparisonTableMobile
                {...props}
                productTierDetails={productTierDetails}
                productIdsToCards={productIdsToCards}
              />
            </div>
          </>
        )}

        {productTierDetailsStatus != 'succeeded' && <Spinner />}
      </div>
    </div>
  );
};

export default withDatasourceCheck()(ComparisonTable);
