import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import Spinner from 'components/spinner/Spinner';
import { useCartData } from 'components/cart/cart-hooks';
import { addCartItem, deleteCartItem } from 'components/cart/cart-slice';
import { GetCartItemsData } from 'components/cart/cart-models';
import { ComponentProps } from 'lib/component-props';
import {
  AnalyticsProductItem,
  getProductCartIndex,
  pushAddToCart,
  pushRemoveFromCart,
  pushSelectPromotion,
  pushViewItem,
  pushViewPromotion,
} from 'lib/google-analytics/commerce';
import { PromotionData } from 'lib/promotions/models/promotion-models';
import { getLimitedTimeOffers } from 'lib/promotions/promotion-slice';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import Image from 'next/image';
import { useEffect, useMemo } from 'react';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

type MarketplaceLimitedTimeOfferProps = ComponentProps & {
  fields: {
    title: Field<string>;
  };
};

const MarketplaceLimitedTimeOffer = (props: MarketplaceLimitedTimeOfferProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();

  const cartState = useAppSelector((state) => state.cart);
  const promotionsState = useAppSelector((state) => state.promotions);
  const { limitedTimeOffers: promotions, status } = promotionsState;
  const { cartAddItemStatus, cartItemsData } = cartState;
  const firstPromotion = promotions.length > 0 ? promotions[0] : null;
  const currencyIsoCode = cartState?.currencyIsoCode;
  const cart = useCartData();

  const productInCart = useMemo(() => {
    return cartItemsData?.cartItems?.find(
      (c) => c.cartItem.productId === firstPromotion?.productDetails?.productId
    );
  }, [cartItemsData?.cartItems, firstPromotion]);

  const promotionName = 'Limited Time Offer';
  const promotionLocation = 'Cart';

  const { data: session, status: sessionStatus } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const onClickAddToCartHandler = (sku: string): void => {
    dispatch(addCartItem({ sku: sku, quantity: 1, bearerToken: bearerToken }));
    if (firstPromotion == null || currencyIsoCode == null || cartState.cartSummaryData == null) {
      return;
    }
    const analyticsItem = generateAnalyticsProductItem(firstPromotion, cart);

    pushAddToCart(
      currencyIsoCode,
      (
        parseFloat(cartState.cartSummaryData.totalProductAmountAfterAdjustments) +
        parseFloat(firstPromotion.discountPrice)
      ).toString(),
      analyticsItem
    );

    pushSelectPromotion(
      promotionName,
      promotionLocation,
      `LimitedTimeOffer_${props.rendering.uid}`,
      firstPromotion.displayName,
      analyticsItem
    );
  };

  const onClickInCartHandler = (): void => {
    if (productInCart) {
      dispatch(
        deleteCartItem({ cartItemId: productInCart.cartItem.cartItemId, bearerToken: bearerToken })
      );

      if (firstPromotion == null || currencyIsoCode == null || cartState.cartSummaryData == null) {
        return;
      }

      pushRemoveFromCart(
        currencyIsoCode,
        (
          parseFloat(cartState.cartSummaryData.totalProductAmountAfterAdjustments) -
          parseFloat(firstPromotion.discountPrice)
        ).toString(),
        generateAnalyticsProductItem(firstPromotion, cart)
      );
    }
  };

  useEffect(() => {
    if (sessionStatus === 'loading') {
      return;
    }

    if (currencyIsoCode !== null && cart?.cartSummary.cartId) {
      dispatch(
        getLimitedTimeOffers({
          currencyIsoCode,
          cartId: cart.cartSummary.cartId,
          bearerToken: bearerToken,
        })
      );
    }
  }, [dispatch, currencyIsoCode, cart?.cartSummary.cartId, bearerToken, sessionStatus]);

  useEffect(() => {
    if (firstPromotion == null) {
      return;
    }

    const analyticsItems = generateAnalyticsProductItem(firstPromotion, cart);

    pushViewPromotion(
      promotionName,
      promotionLocation,
      `LimitedTimeOffer_${props.rendering.uid}`,
      firstPromotion.displayName,
      analyticsItems
    );

    if (currencyIsoCode == null || cart == null) {
      return;
    }

    pushViewItem(
      currencyIsoCode,
      cart.cartSummary.totalProductAmountAfterAdjustments,
      analyticsItems
    );
  }, [cart, currencyIsoCode, firstPromotion, props.rendering.uid]);

  const generateAnalyticsProductItem = (
    promotion: PromotionData,
    cartData: GetCartItemsData | null
  ): AnalyticsProductItem => {
    const index = getProductCartIndex(promotion.productDetails.productId, cartData);
    return {
      id: promotion.displayName,
      sku: promotion.productDetails.sku,
      price: promotion.productDetails.price.toString(),
      discount: (promotion.productDetails.price - parseInt(promotion.discountPrice)).toString(),
      brand: promotion.productDetails.brand,
      name: promotion.displayName,
      variant: promotion.productDetails.productId,
      index: index.toString(),
      itemCategory: promotion.productDetails.productCategoryPaths.primary?.at(-1)?.name,
      itemCategory2: promotion.productDetails.productCategoryPaths.path1?.at(-1)?.name,
      itemCategory3: promotion.productDetails.productCategoryPaths.path2?.at(-1)?.name,
      itemCategory4: promotion.productDetails.productCategoryPaths.path3?.at(-1)?.name,
    };
  };

  return (
    <div className="limited-time-offer text-mp-txt-neutralLight w-full my-10 relative">
      {status != 'succeeded' && <Spinner color="#20BBD0" />}
      {firstPromotion != null && (
        <Text
          tag="h2"
          field={props.fields.title}
          className="text-xl 2xl:text-2xl leading-[29.05px] mb-1 2xl:pt-3 text-center"
        />
      )}
      {firstPromotion != null && (
        <div className="offerContainer border rounded border-base-normal p-6 bg-mp-background-card text-white flex md:space-x-6 flex-col md:flex-row">
          <div className="w-full md:w-1/3 relative">
            <Image
              src="https://cdn-www.avid.com/-/media/Webstore/SpotlightProducts/MC_Ultimate.png"
              alt=""
              width={640}
              height={406}
            />
          </div>

          <div className="flex flex-col text-mp-txt-bright w-full">
            <h2 className="text-center md:text-left font-bold text-base 2xl:text-lg mb-2">
              {firstPromotion.displayName}
            </h2>
            <p className="text-center md:text-left text-base mb-4 text-mp-txt-neutralLight">
              {firstPromotion.productDetails.description}{' '}
              <a className="underline text-left text-base text-mp-txt-primary hover:cursor-pointer">
                {t('Cart_Details')}
              </a>
            </p>
            <div className="flex justify-between">
              <div className="flex flex-col space-y-0.5">
                <h2 className="font-bold text-xl 2xl:text-2xl text-mp-txt-primary">
                  {t(`Currency_Symbol_${currencyIsoCode}`)}
                  {firstPromotion.discountPrice}
                </h2>
                <h2 className="line-through text-mp-txt-neutral text-base">
                  {t(`Currency_Symbol_${currencyIsoCode}`)}
                  {firstPromotion.productDetails.price}
                </h2>
              </div>
              <div className="mt-auto relative">
                {cartAddItemStatus == 'loading' && (
                  <Spinner height={15} width={3} margin={3} color="#20BBD0" />
                )}
                {productInCart ? (
                  <button
                    onClick={(): void => onClickInCartHandler()}
                    className="text-center font-bold text-sm btn-feature pointer-events-none bg-mp-btn-disabled w-24 py-[10px] px-3"
                  >
                    {t('Cart_In_Cart')}
                  </button>
                ) : (
                  <button
                    onClick={(): void =>
                      onClickAddToCartHandler(firstPromotion.productDetails.productId)
                    }
                    className="text-center font-bold text-sm btn-feature text-mp-txt-bright bg-mp-btn-primary hover:bg-mp-btn-light hover:text-mp-txt-almostBlack py-[10px] px-3"
                  >
                    {t('Cart_Add')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withDatasourceCheck()<MarketplaceLimitedTimeOfferProps>(MarketplaceLimitedTimeOffer);
