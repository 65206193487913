import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Field,
  LinkField,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';
import { ComponentProps } from 'lib/component-props';

type ClickthroughBlockIconProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    description: Field<string>;
    link: LinkField;
    icon: ReferenceStringItem;
    noFollow?: Field<boolean>;
  };
  params: {
    Theme: string;
  };
};

const GetClassFromTheme = (theme: string): string => {
  if (theme == null) {
    return '';
  }

  switch (theme.toLowerCase()) {
    case 'light':
      return 'bg-base-lighter text-almost-black border-base-normal';
    case 'dark':
      return 'bg-neutral-darkest text-white border-neutral-darkest';
    default:
      return 'bg-white text-almost-black border-base-normal';
  }
};

const ClickthroughBlockIcon = (props: ClickthroughBlockIconProps): JSX.Element => {
  if (props.params == null) {
    return <div>Rendering parameters are null</div>;
  }

  const themeClass = GetClassFromTheme(props.params.Theme);
  return (
    <div className="clickthrough-block-item px-6 flex justify-center w-full">
      <div
        className={`${themeClass} w-full flex flex-col items-start p-8 md:px-6 lg:px-8 2xl:px-14 text-left rounded max-w-[1208px]`}
      >
        {props.fields.icon?.fields?.value?.value && (
          <FontAwesomeIcon
            icon={props.fields.icon.fields.value.value as IconProp}
            className={`${
              props.params.Theme.toLowerCase() === 'light' ? 'text-base-darker' : 'text-base-normal'
            } text-[46px] lg:text-[61px] mb-4`}
          />
        )}

        <Text tag="h4" field={props.fields.heading} className="text-left align-top mb-2" />
        <RichText field={props.fields.description} className="rte body mb-4" />
        <Link
          field={props.fields.link}
          linkCustomizations={props.fields}
          className={`whitespace-normal ${
            props.params.Theme.toLowerCase() === 'light'
              ? 'btn-link-underline'
              : 'btn-link-underline-dark'
          } mr-auto`}
          hideText
        >
          <span>{props.fields.link?.value?.text}</span>
          <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
        </Link>
      </div>
    </div>
  );
};

export default withDatasourceCheck()<ClickthroughBlockIconProps>(ClickthroughBlockIcon);
