import Link from 'next/link';
import Image from 'next/image';
import { SitecorePageResult } from './product-category-listing-models';
import {
  pushProductClick,
  pushViewItemList,
  AnalyticsProductItem,
} from 'lib/google-analytics/commerce';
import { useAppSelector } from 'lib/store/hooks';
import { useEffect, useRef } from 'react';

const ProductItem = (props: SitecorePageResult): JSX.Element => {
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const productItemRef = useRef(null);
  const productItem = props?.product?.targetItems[0];

  const handleProductClick = (): void => {
    pushProductClick(currencyIsoCode || '', {
      id: productItem?.productId?.value,
      sku: productItem?.productCode?.value,
      name: props.searchTitle?.value,
      price: '',
      discount: '',
      brand: productItem?.brand?.value,
      variant: productItem?.term?.value ?? 'N/A',
      itemCategory: productItem?.primaryCategory?.targetItems[0],
      itemCategory2: productItem?.additionalCategories?.targetItems[0],
      itemCategory3: productItem?.additionalCategories?.targetItems[1],
      itemCategory4: productItem?.additionalCategories?.targetItems[2],
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const analyticsProducts: AnalyticsProductItem[] = [
              {
                id: productItem?.productId?.value,
                name: props.searchTitle?.value,
                brand: productItem?.brand?.value,
                price: '',
                discount: '',
                variant: productItem?.term?.value ?? 'N/A',
                listId: 'ProductCategoryListing',
                listName: 'Product Category Listing',
                itemCategory: productItem?.primaryCategory?.targetItems[0],
                itemCategory2: productItem?.additionalCategories?.targetItems[0],
                itemCategory3: productItem?.additionalCategories?.targetItems[1],
                itemCategory4: productItem?.additionalCategories?.targetItems[2],
                sku: productItem?.productCode?.value,
              },
            ];

            pushViewItemList(
              'ProductCategoryListing',
              'Product Category Listing',
              currencyIsoCode || '',
              analyticsProducts
            );
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (productItemRef.current) {
      observer.observe(productItemRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [
    currencyIsoCode,
    productItem,
    productItem?.additionalCategories?.targetItems,
    productItem?.brand?.value,
    productItem?.primaryCategory?.targetItems,
    productItem?.productCode?.value,
    productItem?.productId?.value,
    productItem?.productName?.value,
    productItem?.term?.value,
    props.searchTitle?.value,
  ]);

  return (
    <div className="product-item flex flex-row items-start gap-6" ref={productItemRef}>
      <div className="w-fit">
        {props.searchImage?.jsonValue?.value?.src && (
          <figure className="list-item-thumbnail">
            <Link href={props.url?.path} passHref legacyBehavior>
              <Image
                src={props.searchImage?.jsonValue?.value?.src}
                width={145}
                height={108}
                alt={props.searchTitle?.value}
                layout="responsive"
              />
            </Link>
          </figure>
        )}
      </div>

      <section className="w-auto">
        <Link href={props.url?.path} passHref legacyBehavior>
          <a className="text-almost-black mb-1 intro-text-link" onClick={handleProductClick}>
            {props.searchTitle?.value}
          </a>
        </Link>
        <p className="text-almost-black">{props.searchDescription?.value}</p>
      </section>
    </div>
  );
};

export default ProductItem;
