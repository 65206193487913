import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

function ContentArea(props: ComponentProps): JSX.Element {
  const t5TemplateId = '03c28360-b27e-41dc-89fa-80ee5a799fcd';
  const context = useSitecoreContext();
  const t14TemplateId = '316be832-6d21-4725-a0eb-65f83b05deaf';
  const pageTemplateId = context?.sitecoreContext.route?.templateId;
  const phName = t5TemplateId === pageTemplateId ? 'content-area-t5' : 'content-area';
  const addWidthforT14 = t14TemplateId === pageTemplateId ? 'xl:w-8/12 xl:max-w-[800px]' : '';
  return (
    <div className={`max-w-full lg:max-w-content mx-auto sm:my-5 lg:my-10 ${addWidthforT14}`}>
      <Placeholder name={phName} rendering={props.rendering} />
    </div>
  );
}

export default ContentArea;
